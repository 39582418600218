import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useTranslation } from "react-i18next";

const AttendanceEmployeeFilter = () => {

  const { t } = useTranslation();

  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const month = [
    { value: 1, label: t('Jan') },
    { value: 2, label: t('Feb') },
    { value: 3, label: t('Mar') },
    { value: 4, label: t('Apr') },
    { value: 5, label: t('May') },
    { value: 6, label: t('June') },
  ];
  const year = [
    { value: 1, label: "2024" },
    { value: 2, label: "2023" },
    { value: 3, label: "2022" },
    { value: 4, label: "2021" },
    { value: 5, label: "2020" },
    { value: 6, label: "2023" },
    { value: 7, label: "2018" },
  ];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  return (
    <>
      {/* Search Filter */}
      <div className="row filter-row">
        <div className="col-sm-3">
          <div className="input-block form-focus select-focus">
            <div className="cal-icon">
              <DatePicker
                selected={selectedDate}
                onChange={handleDateChange}
                type="date"
                className="form-control floating datetimepicker"
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <label className="focus-label">{t('Date')}</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="input-block form-focus select-focus">
            <Select
              options={month}
              placeholder="Select"
              styles={customStyles}
            />

            <label className="focus-label">{t('Select Month')}</label>
          </div>
        </div>
        <div className="col-sm-3">
          <div className="input-block form-focus select-focus">
            <Select options={year} placeholder="Select" styles={customStyles} />
            <label className="focus-label">{t('Select Year')}</label>
          </div>
        </div>
        <div className="col-sm-3">
          <Link to="#" className="btn btn-success btn-block w-100">
          {t('Search')}
          </Link>
        </div>
      </div>
      {/* /Search Filter */}
    </>
  );
};

export default AttendanceEmployeeFilter;
