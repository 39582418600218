import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../Spinner";

import Swal from "sweetalert2";

import { startOfMonth, endOfMonth, format } from "date-fns";

const MarcarFeriaConjuntaModal = ({ colaboradores, onSubmitSuccess }) => {
  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user,
    user_permissoes,
    verificarPermissao,
    getMissao,
  } = AuthUser();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [data1, setData1] = useState("");
  const [data2, setData2] = useState(""); 
  const [gozada, setgozada] = useState(0);
  const [remunerada, setRemunerada] = useState(0);
  const [tipoMarcacao, setTipoMarcacao] = useState(1);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  useEffect(() => {
    const currentDate = new Date();
    setData1(format(currentDate, "yyyy-MM-dd"));
    setData2(format(currentDate, "yyyy-MM-dd"));
  }, [colaboradores]);

  // REGISTO NOVO

  const marcar_feria = async () => {
    try {
      toggleLoading(true);

      // Basic form validation
      if (colaboradores.length < 1) {
        setLoading(false);

        // Show a error toast
        toast.error("Deve selecionar pelo menos um colaborador.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });

        return;
      }

      await registar();
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } finally {
      toggleLoading(false);
    }
  };

  const registar = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("colaboradores", JSON.stringify(colaboradores));
      formData.append("data1", data1);
      formData.append("dia1", format(data1, "d"));
      formData.append("mes1", format(data1, "M"));
      formData.append("ano1", format(data1, "yyyy"));

      formData.append("data2", data2);
      formData.append("dia2", format(data2, "d"));
      formData.append("mes2", format(data2, "M"));
      formData.append("ano2", format(data2, "yyyy"));

      formData.append("remunerada", 1);
      formData.append("saiu", 0);
      formData.append("registou", user?.id);
      formData.append("justificada", 0);
      formData.append("tipoMarcacao", tipoMarcacao);
      formData.append("missao_id", getMissao()?.id);

      const response = await http.post("/ferias/conjunta/marcar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setLoading(false);

      Swal.fire({
        title: "INFORMAÇÃO",
        text: response?.data?.mensagem,
        type: "success",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        }
      );
    }
  };

  return (
    <>
      {/* Personal Info Modal */}
      <div
        id="marcar_feria_conjunta_modal"
        className="modal custom-modal fade"
        role="dialog"
      >
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                <i className="fa fa-calendar" /> Marcação De feria Para
                Selecionados - [{colaboradores?.length}]
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>

            <div className="modal-body">
              <div className="card">
                <div className="card-body">
                  <form>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <input
                            className="form-control"
                            type="date"
                            value={data1}
                            onChange={(e) => setData1(e.target.value)}
                          />
                          <label className="focus-label">
                            {t("Defina data de saida")}
                          </label>
                        </div>

                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <input
                            className="form-control"
                            type="date"
                            value={data2}
                            onChange={(e) => setData2(e.target.value)}
                          />
                          <label className="focus-label">
                            {t("Defina data de regresso")}
                          </label>
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="input-block mb-3 mb-3 form-focus focused">
                          <select
                            className="form-control select"
                            onChange={(e) => setTipoMarcacao(e.target.value)}
                          >
                            <option value="1">Marcar feria</option>
                            <option value="2">Remover feria</option>
                          </select>

                          <label className="focus-label">
                            {t("Marcar / Desmarcar")}{" "}
                            <span className="text-danger">*</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="submit-section m-t-20 text-center">
                      <a
                        className="btn btn-primary submit-btn"
                        onClick={marcar_feria}
                      >
                        {t("Marcar a feria")}
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* /Personal Info Modal */}

      <ToastContainer />

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default MarcarFeriaConjuntaModal;
