import React, { useState, useEffect } from "react";
import { Table } from "antd";
import {
  User1,
  User2,
  User3,
  User4,
  User5,
  User6,
  User7,
  User8,
  User9,
  User10,
  User11,
  User12,
} from "../../../../Routes/ImagePath";
import { Link } from "react-router-dom";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";

import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../../components/Spinner";
import DescontoModal from "../../../../components/Administration/financas/DescontoModal";
import SubsidioVinculoModal from "../../../../components/Administration/financas/SubsidioVinculoModal";

const DescontoTable = ({ verPemissoes }) => {
  const { t } = useTranslation();

  const [subsidio, setsubsidio] = useState([]);
  const [subsidioselecionado, setsubsidioSelecionado] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } = AuthUser();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [selectedUserIds, setSelectedUserIds] = useState([]);

  useEffect(() => {
   
  }, []);

  const handleSubmissionSuccess = (subsidios) => {
    const filteredSubsidios = subsidios?.filter(
      (subsidio) => subsidio.tipo == 2
    );
    setsubsidio(filteredSubsidios);
  };

  const chamarModalEliminar = (idSelecionado) => {
    setsubsidioSelecionado(idSelecionado);
    const editButton = document.getElementById("triggerModalEliminar");

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalSubsidioVinculo = (idSelecionado) => {
  
    getVinculos(idSelecionado);

  };

  const getVinculos = async (subsidio) => {
    try {
      
      setLoading(true); // Set loading to true when the request starts

      const formData = new FormData();
      formData.append("id", subsidio?.id);

      const response = await http.post("/subsidio/vinculos", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      verPemissoes(response?.data, subsidio);

      if (response?.data) {
        const editButton = document.getElementById(
          "triggerModalEditSubsidioVinculo"
        );

        if (editButton) {
          editButton.click();
        }
      }

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const chamarModalEdit = (idSelecionado) => {

    setsubsidioSelecionado(idSelecionado);
    const editButton = document.getElementById("triggerModalEditDesconto");

    if (editButton) {
      editButton.click();
    }
  };

  const handleAtivarDesativar = async (id, newState) => {
    //console.log('Clicou aqui para ativar e desativar');

    if (verificarPermissao("CARGO", "ativar")) {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append("id", id);
        formData.append("estado", newState);

        const response = await http.post("/subsidio/activate", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        setLoading(false);

        const filteredSubsidios = response?.data?.data?.filter(
          (subsidio) => subsidio.tipo == 2
        );
        setsubsidio(filteredSubsidios);

        // Atualize a lista de utilizadores após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const getSubsidios = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/descontos/"+getMissao()?.id);
      const filteredSubsidios = res.data.subsidios.filter(
        (subsidio) => subsidio.tipo == 2
      );

      setsubsidio(filteredSubsidios);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const columns = [
    {
      title: "Designação",
      dataIndex: "nome",
      sorter: (a, b) => a.nome.length - b.nome.length,
    },
    {
      title: "Valor",
      dataIndex: "valor",
      render: (text, record) => (
        <>
          <span>
            {record?.natureza == 1 ? record?.valor : record?.valor + "%"}
          </span>
        </>
      ),
      sorter: (a, b) => a.valor.length - b.valor.length,
    },
    {
      title: "Natureza",
      dataIndex: "natureza",
      render: (text, record) => (
        <>
          <span>{record?.natureza == 1 ? "Fixo" : "Percentual"}</span>
        </>
      ),
      sorter: (a, b) => a.local.length - b.local.length,
    },
    {
      title: t("Status"),
      dataIndex: "estado",
      render: (text, record) => (
        <>
          <span
            className={
              record?.estado == 1
                ? "badge bg-inverse-success"
                : "badge bg-inverse-danger"
            }
          >
            {record?.estado == 1 ? "Ativo" : "Desativado"}
          </span>
          &nbsp;
          <span
            onClick={() => handleAtivarDesativar(record.id, text == 1 ? 0 : 1)}
          >
            <i className="fa fa-recycle"></i>
          </span>
        </>
      ),
      sorter: (a, b) => a.estado.length - b.estado.length,
    },

    {
      title: t("Action"),
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <span
              className="dropdown-item"
              title={record?.nome}
              onClick={() => chamarModalEdit(record)}
            >
              <i className="fa fa-pencil m-r-5" /> {t("Edit")}
            </span>
            <span
              className="dropdown-item"
              title={record?.nome}
              onClick={() => chamarModalSubsidioVinculo(record)}
            >
              <i className="fa fa-link m-r-5" /> {t("Vinculos")}
            </span>
            <span
              className="dropdown-item"
              title={record?.nome}
              onClick={() => chamarModalEliminar(record)}
            >
              <i className="fa fa-trash m-r-5" /> {t("Delete")}
            </span>
          </div>
        </div>
      ),
    },
  ];

  if (loading)
    return (
      <>
        <Spinner>
          <ToastContainer />
        </Spinner>
      </>
    );
  else
    return (
      <>
        <div className="text-end mb-4 clearfix">
          &nbsp;
          <button
            className="btn btn-primary add-btn"
            type="button"
            data-bs-toggle="modal"
            data-bs-target="#add_desconto_modal"
          >
            <i className="fa-solid fa-plus" /> {t("Add Desconto")}
          </button>
          &nbsp;
          <button
            className="btn btn-primary add-btn"
            onClick={() => getSubsidios()}
          >
            <i className="fa-solid fa-search" />
          </button>
          &nbsp;
        </div>

        <div className="payroll-table card">
          <div className="table-responsive">
            <Table
              className="table table-hover table-radius"
              style={{ overflowX: "auto" }}
              columns={columns}
              dataSource={subsidio}
              rowKey={(record) => record.id}
            />

            <DescontoModal
              onSubmissionSuccess={handleSubmissionSuccess}
              subsidio={subsidioselecionado}
            />

            <DeleteModal
              objectoName={t("subsidio")}
              onSubmissionSuccess={handleSubmissionSuccess}
              id={subsidioselecionado?.id}
              endpoint={"subsidio"}
            />

            <span
              data-bs-toggle="modal"
              data-bs-target="#edit_desconto_modal"
              id="triggerModalEditDesconto"
            ></span>

            <span
              data-bs-toggle="modal"
              data-bs-target="#delete"
              id="triggerModalEliminar"
            ></span>

          </div>
        </div>

        <ToastContainer />
      </>
    );
};

export default DescontoTable;
