import React, { useState, useEffect } from "react";

import Breadcrumbs from "../../../../components/Breadcrumbs";
import DepartamentoFilter from "./DepartamentoFilter";
import DepartamentoTable from "./DepartamentoTable";
import DepartamentoModal from "../../../../components/Administration/departamentos/DepartamentoModal";

import { useTranslation } from "react-i18next";

import Swal from "sweetalert2";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from 'react-toastify';
import Spinner from "../../../../components/Spinner";

const Departamento = () => {

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } = AuthUser();
  const [departamentos, setDepartamentos] = useState([]);  

  const [filtereddepartamentos, setfiltereddepartamentos] = useState([]);

  const { t } = useTranslation();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  // Callback function to receive filtered users from UserFilter
  const handleFilterSubmit = (filtereddepartamentos) => {

    console.log('received:');
    console.log(filtereddepartamentos);
     
    setfiltereddepartamentos(filtereddepartamentos); 
  };


  const handleSubmissionSuccess = (departamentos) => {
    // Refresh acessos state or trigger any necessary actions
    setDepartamentos(departamentos);    
  };

  const getdepartamentos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get('/departamentos/'+getMissao()?.id);

      setDepartamentos(res.data.departamentos);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  }; 
 

  useEffect(() => {

    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()
        await getdepartamentos();

      } catch (error) {
        console.error('Erro ao obter departamentos:', error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  if (verificarPermissao('DEPARTAMENTO', 'ver')) 
  if (loading) return (<><Spinner></Spinner></>); else return (
    <>
      (
      <>
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle={t('Department')}
          title="Dashboard"
          subtitle={t('Department')}
          modal="#add_departamento_modal"
          name={t('Add Department')}
        />

        <DepartamentoFilter onFilterSubmit={handleFilterSubmit} />
        <DepartamentoTable departamentos={filtereddepartamentos.length > 0 ? filtereddepartamentos : departamentos} />
        <DepartamentoModal onSubmissionSuccess={handleSubmissionSuccess} />

      </div>
    </div> </>
        ); </>
    );
  else
    return (<>
      <div className="page-wrapper cardhead">
        <div className="content ">
          {/* Page Header */}
          <div className="page-header">
            <div className="row">
              <div className="col-sm-12">
                <h3 className="page-title">SEM PERMISSÃO</h3>

              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-12">

              <div className="card">
                <div className="card-header">
                  <h4 className="card-title"><i className="fa fa-times-circle text-danger"></i>&nbsp; OPERAÇÃO NÃO AUTORIZADA</h4>
                </div>
                <div className="card-body">
                  <p>
                    Esta operação não é autorizada para o seu nivel de acesso ao sistema, por favor contacte o administrador do sistema.
                  </p>
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>)
};


export default Departamento;
