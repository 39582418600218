import React, { useState, useEffect } from "react";

import Breadcrumbs from "../../../../../components/Breadcrumbs";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddSalaryModal from "../../../../../components/modelpopup/AddSalaryModal";
import SalaryTable from "./SalaryTable";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../../components/AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../../../../components/Spinner";

import { startOfMonth, endOfMonth, format } from "date-fns";

import { Link, useParams, useNavigate } from "react-router-dom";

import DeleteModal from "../../../../../components/modelpopup/DeleteModal";

import Swal from "sweetalert2";
import UserFilter from "../../../Administration/Users/UserFilter";
import FolhaSalarialFilter from "../../../Administration/financas/FolhaSalarialFilter";

const SalarySheets = () => {
  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao, user, getMissao } = AuthUser();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [setSelectedOption] = useState(null);
  const [setSelecttwo] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedDateTwo, setSelectedDateTwo] = useState(null);

  const [dateTwo, setdateTwo] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const [cargos, setCargos] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [postos, setPostos] = useState([]);
  const [folhas, setFolhas] = useState([]);


  const [selectedFolhas, setSelectedFolhas] = useState([]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const meses = [
    "Janeiro",
    "Fevereiro",
    "Março",
    "Abril",
    "Maio",
    "Junho",
    "Julho",
    "Agosto",
    "Setembro",
    "Outubro",
    "Novembro",
    "Dezembro",
  ];

  useEffect(() => {


    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()
        //await getUtilizador();

        await getAssets();
      } catch (error) {
        console.error("Erro ao obter utilizadores:", error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  const handleFolhaSelect = (selectedFolha) => {
    setSelectedFolhas((prevSelectedFolhas) => {
      const isAlreadySelected = prevSelectedFolhas.some(
        (folha) => folha.id == selectedFolha.id
      );

      if (isAlreadySelected) {
        return prevSelectedFolhas.filter(
          (folha) => folha.id != selectedFolha.id
        );
      } else {
        return [...prevSelectedFolhas, { ...selectedFolha }];
      }
    });
  };

  const getFolhaClass = (folhaId) => {
    if (isSelected(folhaId)) {
      return "bg-primary text-white";
    }
    return "";
  };

  const isSelected = (folhaId) => {
    return selectedFolhas.some((folha) => folha.id == folhaId);
  };

  const getAssets = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/salario/folhas/"+getMissao()?.id);

      setFolhas(res?.data?.folhas);
      setCargos(res?.data?.cargos);
      setDepartamentos(res?.data?.departamentos);
      setPostos(res?.data?.postos);
      setBancos(res?.data?.bancos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleFilterSubmit = (folhas) => {
    setFolhas(folhas);
  };
  const chamarModalEliminar = (idSelecionado) => {
    handleEliminar(idSelecionado);
  };

  const handleEliminar = async (idSelecionado) => {
    Swal.fire({
      title: "Remover folha salarial?",
      text: "Tem a certeza que pretende remover esta folha salarias e seus items?",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {
      if (t.isConfirmed) {
        try {
          setLoading(true);

          try {
            const formData = new FormData();
            formData.append("id", idSelecionado?.id);

            console.log("Folha ID:");
            console.log(idSelecionado?.id);

            const response = await http.post(
              "/folha-salarial/eliminar",
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );

            getAssets();

            // Atualize a lista de acessos após ativar/desativar
          } catch (error) {
            console.error(error);

            toast.error(
              "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          } finally {
            setLoading(false);
          }

          setLoading(false);
        } catch (error) {
          setLoading(false);

          toast.error(
            "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      }
    });
  };

  const handleSubmissionSuccess = (folha_id) => {
    // Refresh acessos state or trigger any necessary actions
    navigate("/salary-sheet-employees/" + folha_id);
  };

  const submitFolhaSlarialPDFreport = async (folha_id) => {
    try {
      setLoading(true);

      await getReportFolha(folha_id);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getReportFolha = async (folha_id) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("folha_id", folha_id);

      const response = await http.post("/folha_salarial/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const getReportFolhaExcel = async (folha_id) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("folha_id", folha_id);

      const response = await http.post("/folha_salarial/excel", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(url_root+''+response?.data?.url, "_blank");

    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const submitFolhaSlarialExcelreport = async (folha_id) => {
    try {
      setLoading(true);

      await getReportFolhaExcel(folha_id);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getTotalValor = () => {
    return folhas.reduce((total, folha) => total + parseFloat(folha.total), 0);
  };

  const getTotalNaoPaga = () => {
    return folhas.reduce((total, folha) => {
      return folha.paga != 1 ? total + parseFloat(folha.total) : total;
    }, 0);
  };

  const getTotalPaga = () => {
    return folhas.reduce((total, folha) => {
      return folha.paga == 1 ? total + parseFloat(folha.total) : total;
    }, 0);
  };

  const getTotalSelecionadas = () => {
    return selectedFolhas.reduce(
      (total, folha) => total + parseFloat(folha.total),
      0
    );
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t("Salary Sheets")}
            title="Dashboard"
            subtitle={t("Salary")}
            modal="#add_group_salary"
            name={t("Add Salary")}
          />

          <div className="row">
            <div className="col-md-12">
              <div className="card-group m-b-30">
                {/* Total */}
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-3">
                      <div>
                        <span className="d-block">{t("Total")}</span>
                      </div>
                      <div>
                        <span className={"text-dark"}>
                          {Math.round(
                            (100 * getTotalValor()) / getTotalValor()
                          )}
                          %
                        </span>
                      </div>
                    </div>
                    <h3 className="mb-3">{formatNumber(getTotalValor())}</h3>
                    <div className="progress mb-2" style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-dark"
                        role="progressbar"
                        style={{
                          width: `${Math.round(
                            (100 * getTotalValor()) / getTotalValor()
                          )}%`,
                        }}
                        aria-valuenow={40}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                </div>

                {/* Entrada */}
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-3">
                      <div>
                        <span className="d-block">{t("Pagas")}</span>
                      </div>
                      <div>
                        <span className={"text-success"}>
                          {Math.round((100 * getTotalPaga()) / getTotalValor())
                            ? Math.round(
                                (100 * getTotalPaga()) / getTotalValor()
                              )
                            : 0}
                          %
                        </span>
                      </div>
                    </div>

                    <h3 className="mb-3">{formatNumber(getTotalPaga())}</h3>

                    <div className="progress mb-2" style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-success"
                        role="progressbar"
                        style={{
                          width: `${Math.round(
                            (100 * getTotalPaga()) / getTotalValor()
                          )}%`,
                        }}
                        aria-valuenow={40}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                </div>

                {/* Saidas */}
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-3">
                      <div>
                        <span className="d-block">{t("Nao pagas")}</span>
                      </div>
                      <div>
                        <span className={"text-danger"}>
                          {Math.round(
                            (100 * getTotalNaoPaga()) / getTotalValor()
                          )
                            ? Math.round(
                                (100 * getTotalNaoPaga()) / getTotalValor()
                              )
                            : 0}
                          %
                        </span>
                      </div>
                    </div>
                    <h3 className="mb-3">{formatNumber(getTotalNaoPaga())}</h3>
                    <div className="progress mb-2" style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-danger"
                        role="progressbar"
                        style={{
                          width: `${Math.round(
                            (100 * getTotalNaoPaga()) / getTotalValor()
                          )}%`,
                        }}
                        aria-valuenow={40}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                </div>

                {/* Saidas */}
                <div className="card">
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-3">
                      <div>
                        <span className="d-block">{t("Selecionadas")}</span>
                      </div>
                      <div>
                        <span className={"text-primary"}>
                          {Math.round(
                            (100 * getTotalSelecionadas()) / getTotalValor()
                          )
                            ? Math.round(
                                (100 * getTotalSelecionadas()) / getTotalValor()
                              )
                            : 0}
                          %
                        </span>
                      </div>
                    </div>
                    <h3 className="mb-3">
                      {formatNumber(getTotalSelecionadas())}
                    </h3>
                    <div className="progress mb-2" style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{
                          width: `${Math.round(
                            (100 * getTotalSelecionadas()) / getTotalValor()
                          )}%`,
                        }}
                        aria-valuenow={40}
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <FolhaSalarialFilter
            onFilterSubmit={handleFilterSubmit}
            departamentos={departamentos}
            cargos={cargos}
            postos={postos}
            folhasSelecionadas={selectedFolhas}
          />

          <div className="row">
            <div className="col-sm-12">
              <div className="table-responsive">
                <table className="table table-striped table-responsive">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Folha Salarial</th>
                      <th>
                        <i className="fa fa-user"></i>
                      </th>
                      <th>Mês</th>
                      <th>Ano</th>
                      <th>Total (kz)</th>
                      <th>Pagamento</th>
                      <th>Exportar</th>
                      <th>
                        <i className="fa fa-cogs"></i>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {folhas?.slice(0, 356).map((item, index) => (
                      <tr key={index} title={item?.designacao+" - "+item?.banco?.nome}>
                        <td>{index + 1}</td>
                        <td
                          onClick={() => handleFolhaSelect(item)}
                          className={`${getFolhaClass(item.id)}`}
                        >
                          <Link
                            to={"/salary-sheet-employees/" + item?.id}
                            className="btn btn-white btn-sm btn-rounded"
                          >
                            {item?.designacao}
                          </Link>
                        </td>
                        <td>{item?.salarios?.length}</td>
                        <td>{meses[item?.mes - 1]}</td> {/* Nome do mês */}
                        <td>{item?.ano}</td>
                        <td>{formatNumber(item?.total)}</td>
                        <td>
                          {item?.paga != 1 ? (
                            <>
                              <Link className="btn btn-white btn-sm btn-rounded">
                                <i className="far fa-dot-circle text-danger" />{" "}
                                {t("Not Paid")}
                              </Link>
                            </>
                          ) : (
                            <>
                              <Link
                                className="btn btn-white btn-sm btn-rounded"
                                title={format(
                                  new Date(item?.data_pagamento),
                                  "dd/MM/yyyy"
                                )}
                              >
                                <i className="far fa-dot-circle text-success" />{" "}
                                {t("Paid")}
                              </Link>
                            </>
                          )}
                        </td>
                        <td>
                          <span
                            className="btn btn-sm btn-danger text-white"
                            onClick={() =>
                              submitFolhaSlarialPDFreport(item?.id)
                            }
                          >
                            <i className="fa fa-file-pdf"></i> {t("Exportar")}
                          </span>

                          <span
                            className="btn btn-sm btn-success text-white"
                            onClick={() =>
                              submitFolhaSlarialExcelreport(item?.id)
                            }
                          >
                            <i className="fa fa-file-excel"></i> {t("Exportar")}
                          </span>

                        </td>
                        <td>
                          <div className="dropdown dropdown-action text-end">
                            <Link
                              to="#"
                              className="action-icon dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="material-icons">more_vert</i>
                            </Link>

                            <div className="dropdown-menu dropdown-menu-right">
                              <span
                                className="dropdown-item"
                                onClick={() =>
                                  submitFolhaSlarialPDFreport(item?.id)
                                }
                              >
                                <i className="fa fa-file-pdf m-r-5 text-danger" />{" "}
                                {t("Exportar PDF")}
                              </span>

                              {item?.paga != 1 ? (
                                <>
                                  <span
                                    className="dropdown-item"
                                    onClick={() => chamarModalEliminar(item)}
                                  >
                                    <i className="fa fa-trash m-r-5" />{" "}
                                    {t("Delete")}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          {loading ? (
            <>
              <Spinner></Spinner>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <AddSalaryModal
        bancos={bancos}
        cargos={cargos}
        departamentos={departamentos}
        postos={postos}
        onSubmissionSuccess={handleSubmissionSuccess}
      />
    </>
  );
};

export default SalarySheets;
