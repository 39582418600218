/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";

import Breadcrumbs from "../../../../../components/Breadcrumbs";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddSalaryModal from "../../../../../components/modelpopup/AddSalaryModal";
import SalaryTable from "./SalaryTable";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../../components/AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../../../../components/Spinner";

import { startOfMonth, endOfMonth, format } from "date-fns";

import { Link, useParams, useNavigate } from "react-router-dom";
import AddColaboradorFolhaSalarialModal from "../../../../../components/modelpopup/AddColaboradorFolhaSalarialModal";

import Swal from "sweetalert2";
import RegistarPagamentoFolhaSalarialConjuntaModal from "../../../../../components/modelpopup/RegistarPagamentoFolhaSalarialConjuntaModal";
import RegistarPagamentoFolhaSalarialUnicaModal from "../../../../../components/modelpopup/RegistarPagamentoFolhaSalarialUnicaModal";
import AlterarBancoFolhaSalarialModal from "../../../../../components/modelpopup/AlterarBancoFolhaSalarialModal";

const EmployeeSalarySheet = () => {
  const navigate = useNavigate();
  // navigate('/admin-dashboard');

  let { FolhaID } = useParams();

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao, user } = AuthUser();
  const [loading, setLoading] = useState(false);

  const [processados, setProcessados] = useState([]);
  const [salarios, setSalarios] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [folha, setFolha] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedFolhas, setSelectedFolhas] = useState([]);

  const [setSelectedOption] = useState(null);
  const [setSelecttwo] = useState(null);

  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getSalariosDaFolha();
      } catch (error) {
        console.error("Erro ao obter utilizadores:", error);
      }
    };

    fetchData();
  }, []);

  const submitFolhaSlarialExcelreport = async (folha_id) => {
    try {
      setLoading(true);

      await getReportFolhaExcel(folha_id);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getReportFolhaExcel = async (folha_id) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("folha_id", folha_id);

      const response = await http.post("/folha_salarial/excel", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(url_root + "" + response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const adicionarFolha = () => {
    if (folha) {
      setSelectedFolhas([...selectedFolhas, folha]);
    }
  };

  const getSalariosDaFolha = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/folha-salarial/salarios/" + FolhaID);

      setSalarios(res?.data?.salarios);
      setBancos(res?.data?.bancos);
      setProcessados(res?.data?.processados);
      setFolha(res?.data?.folha);
      setColaboradores(res?.data?.colaboradores);

      adicionarFolha(res?.data?.folha);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleOnSubmitSuccess = () => {
    getSalariosDaFolha();
  };

  const definir_assets = (res) => {
    try {
      setSalarios(res?.data?.salarios);
      setProcessados(res?.data?.processados);
      setFolha(res?.data?.folha);
      setColaboradores(res?.data?.colaboradores);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const chamarModalEliminar = (colaborador_id) => {
    handleEliminar(colaborador_id);
  };

  const handleEliminar = async (colaborador_id) => {
    Swal.fire({
      title: "Remover colaborador da folha salarial?",
      text: "Tem a certeza que pretende remover este colaborador desta folha?",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {
      if (t.isConfirmed) {
        try {
          setLoading(true);

          try {
            const formData = new FormData();
            formData.append("colaborador_id", colaborador_id);
            formData.append("folha_id", folha?.id);

            const res = await http.post(
              "/folha-salarial/colaborador/eliminar",
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );

            setSalarios(res?.data?.salarios);
            setProcessados(res?.data?.processados);
            setFolha(res?.data?.folha);
            setColaboradores(res?.data?.colaboradores);

            setLoading(false);

            // Atualize a lista de acessos após ativar/desativar
          } catch (error) {
            setLoading(false);
            console.error(error);

            toast.error(
              "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          } finally {
            setLoading(false);
          }

          setLoading(false);
        } catch (error) {
          setLoading(false);

          toast.error(
            "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      }
    });
  };

  const chamarModalRegistarPagamentoConjunta = () => {
    const editButton = document.getElementById("triggerMarcarFaltaConjunta");

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalAlterarBanco = () => {
    const editButton = document.getElementById("triggerModalAlterarBanco");

    if (editButton) {
      editButton.click();
    }
  };

  const submitFolhaSlarialPDFreport = async () => {
    try {
      setLoading(true);

      await getReportFolha();

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Utilizador não autenticado.");

        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        setError(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado"
        );

        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getReportFolha = async () => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("folha_id", folha?.id);

      const response = await http.post("/folha_salarial/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const submitReciboPDFreport = async (recibo_id) => {
    try {
      setLoading(true);

      await getReportRecibo(recibo_id);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getReportRecibo = async (recibo_id) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("recibo_id", recibo_id);

      const response = await http.post("/recibo_salarial/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t("Employee Salary Sheet")}
            title="Dashboard"
            subtitle={t("Salary")}
            modal="#add_colaborador_folha"
            name={t("Add Colaborador")}
          />

          <div className="row mb-4">
            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
              <span
                className="btn btn-primary"
                onClick={() => chamarModalAlterarBanco()}
              >
                <i className="fa fa-bank text-white" /> {t("Alterar O Banco")}
              </span>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
              <span
                title={folha?.designacao}
                className="btn btn-success"
                onClick={() => submitFolhaSlarialExcelreport(folha?.id)}
              >
                <i className="fa fa-file-excel"></i>&nbsp;Exportar Excel
              </span>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
              <span
                title={folha?.designacao}
                className="btn btn-danger"
                onClick={() => submitFolhaSlarialPDFreport()}
              >
                <i className="fa fa-file-pdf"></i>&nbsp;Exportar PDF
              </span>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
              {folha?.paga != 1 ? (
                <>
                  <span
                    className="btn btn-white btn-rounded"
                    onClick={() => chamarModalRegistarPagamentoConjunta()}
                  >
                    <i className="far fa-dot-circle text-danger" />{" "}
                    {t("Not Paid")}
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="btn btn-white btn-rounded"
                    onClick={() => chamarModalRegistarPagamentoConjunta()}
                    title={format(
                      new Date(folha?.data_pagamento),
                      "dd/MM/yyyy"
                    )}
                  >
                    <i className="far fa-dot-circle text-success" /> {t("Paid")}{" "}
                    - Em {format(new Date(folha?.data_pagamento), "dd/MM/yyyy")}
                  </span>
                </>
              )}
            </div>
          </div>

          <table
            className="table table-striped table-responsive"
            style={{ overflowX: "auto" }}
          >
            <thead>
              <tr>
                <th colSpan={7}>
                  {folha?.designacao?.toUpperCase()} - [{" "}
                  {folha?.banco?.nome?.toUpperCase()} ]
                </th>
              </tr>
            </thead>
          </table>

          <table
            className="table table-striped table-responsive"
            style={{ overflowX: "auto" }}
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th>Função/Tel</th>
                <th>CONTA</th>
                <th>IBAN</th>
                <th>Salário (kz)</th>
                <th>Recibo</th>
                <th>
                  <i className="fa fa-cogs"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              {salarios?.slice(0, 356).map((item, index) => (
                <tr key={index} title={item?.colaborador?.name}>
                  <td>{index + 1}</td>
                  <td>
                    <div className="table-avatar">
                      <Link
                        to={"/profile/" + item?.colaborador_id}
                        className="avatar"
                      >
                        <img
                          alt=""
                          src={
                            url_root +
                            "/storage/" +
                            item?.colaborador?.fotografia
                          }
                        />
                      </Link>
                      <Link to={"/profile/" + item?.colaborador_id}>
                        <span>{item?.colaborador?.name}</span>
                      </Link>
                    </div>
                  </td>
                  <td>
                    {item?.cargo}
                    <br></br>
                    {item?.colaborador?.telefone}
                  </td>
                  <td>{item?.conta}</td> {/* Nome do mês */}
                  <td>{item?.IBAN}</td>
                  <td>{formatNumber(item?.salario_iliquido)}</td>
                  <td>
                    <Link
                      className="dropdown-item"
                      to={"/salary-view/" + item?.id}
                    >
                      <i className="fa fa-eye m-r-5" /> {t("Ver Recibo")}
                    </Link>
                  </td>
                  <td>
                    <div className="dropdown dropdown-action text-end">
                      <Link
                        to="#"
                        className="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </Link>

                      <div className="dropdown-menu dropdown-menu-right">
                        <span
                          className="btn btn-sm btn-secondary text-white"
                          onClick={() => submitReciboPDFreport(item?.id)}
                        >
                          <i className="fa fa-file-pdf"></i>&nbsp;PDF
                        </span>

                        {item?.paga != 1 ? (
                          <>
                            <span
                              className="dropdown-item"
                              onClick={() =>
                                chamarModalEliminar(item?.colaborador_id)
                              }
                            >
                              <i className="fa fa-trash m-r-5" />{" "}
                              {t("Remover Da Folha")}
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            <thead>
              <tr>
                <th>#</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th className="bg-primary text-dark">
                  {formatNumber(folha?.total)}
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
          </table>

          {loading ? (
            <>
              <Spinner></Spinner>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <AddColaboradorFolhaSalarialModal
        utilizadores={colaboradores}
        salarios={salarios}
        processados={processados}
        folha={folha}
        onSubmissionSuccess={definir_assets}
      />

      <span
        data-bs-toggle="modal"
        data-bs-target="#marcar_falta_conjunta_modal"
        id="triggerMarcarFaltaConjunta"
      ></span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#alterar_banco_modal"
        id="triggerModalAlterarBanco"
      ></span>

      <RegistarPagamentoFolhaSalarialUnicaModal
        folha_id={folha?.id}
        onSubmitSuccess={handleOnSubmitSuccess}
      />

      <AlterarBancoFolhaSalarialModal
        folha_id={folha?.id}
        bancos={bancos}
        onSubmitSuccess={handleOnSubmitSuccess}
      />
    </>
  );
};

export default EmployeeSalarySheet;
