import Select from "react-select";
import { useTranslation } from "react-i18next";

import React, { useState, useEffect } from "react";

import AuthUser from "../../AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../Spinner";

import { Link, useHistory } from "react-router-dom";

import Swal from "sweetalert2";

const AddColaboradorModal = ({
  onSubmissionSuccess,
  accoes,
  missoes,
  departamentos,
  cargos,
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user,
    user_permissoes,
    verificarPermissao,
    getMissao,
  } = AuthUser();
  const [name, setName] = useState("");
  const [cargo_id, setCargoID] = useState(-1);
  const [departamento_id, setDepartamentoID] = useState(-1);
  const [missao_id, setMissaoID] = useState(getMissao()?.id);
  //eslint-disable-next-line no-unused-vars
  const [telefone, setTelefone] = useState("");
  const [nif, setNif] = useState("");
  const [nif_expira, setNifExpira] = useState("");
  const [nivel_academico, setAcademico] = useState("");
  const [data_nasc, setDataNasc] = useState(new Date());
  const [genero, setGenero] = useState(1);
  const [endereco, setEndereco] = useState("");
  const [seg_social, setSegurancaSocial] = useState("");
  //eslint-disable-next-line no-unused-vars
  const [nivel_id, setNivel] = useState(0);
  const [tipo, setTipo] = useState(3);
  const [niveis, setNiveis] = useState([]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("123456789");
  const [password2, setPassword2] = useState("123456789");

  const [SalarioColetivo, setTipoSalario] = useState(false);
  const [salario, setSalario] = useState(0);

  const [admissao, setAdmissao] = useState(0);
  const [fim_contrato, setFimContrato] = useState(0);
  const [irt, setIRThablitado] = useState(0);
  const [inss, setINSShablitado] = useState(0);
  const [expatriado, setExpatriado] = useState(0);

  const [nivelSelecionado, setNivelSelecionado] = useState(0);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [fotografia, setSelectedFile] = useState(null);

  const [permissaoAdicionar, setPermissaoAdicionar] = useState(false);

  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [permissoes_lista, setPermissoes] = useState([]);
  const [accoes_lista, setAccoes] = useState([]);

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event("click"));
    }
  };

  const handleSelectExpatriadoChange = (valor) => {
    setExpatriado(valor);
  };

  const handleSelectIRTChange = (valor) => {
    setIRThablitado(valor);
  };

  const handleSelectINSSChange = (valor) => {
    setINSShablitado(valor);
  };

  const handleSalarioChanged = (salario) => {
    setSalario(salario);
  };

  const setSalarioColetivo = (tipo) => {
    setTipoSalario(tipo);
    if (tipo) handleSelectCargoChangeSalario(); //do cargo
    else setSalario(0);
  };

  const handleSelectCargoChangeSalario = (cargo_id) => {
    setCargoID(cargo_id);
    // Encontre o objeto correspondente com base no ID
    const selectedObject = cargos?.find(
      (obj) => obj.id == parseInt(cargo_id, 10)
    );
    // Se o objeto for encontrado, defina os valores dos campos de entrada
    if (SalarioColetivo)
      if (selectedObject) {
        setSalario(selectedObject.salario);
      }
  };

  useEffect(() => {
    setAccoes(accoes);

    console.log("Accoes Modal Novo:");
    console.log(accoes);

    setSalarioColetivo(true);

    setPermissaoAdicionar(verificarPermissao("UTILIZADORES", "adicionar"));

    setTipo(1); //Tipo usitilizador normal
    getNiveis();

    const fetchData = async () => {
      try {
        getPermissoes(niveis[0]?.id);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [permissaoAdicionar]);

  const getNiveis = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      /*
      const res = await http.get("/niveis_errado");

      console.log("Carregado...");
      console.log(res.data);

      setNiveis(res.data.niveis);

      setNivel(res.data.niveis[0].id);

      console.log("Fetching permissoes...");

      setNivelSelecionado(res.data.niveis[0].id);*/

      /*try {

        setLoading(true);

        const formData = new FormData();
        formData.append('nivel_id', res.data.niveis[0].id);

        const res = await http.post('/permissoes', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        setPermissoes(res.data.permissoes);

        console.log('Permissões :');
        console.log(res.data.permissoes);

      } catch (error) {
        console.error('Ocorreu um erro ao carregar os permissões:', error);
      } finally {
        setLoading(false);
      }*/

      //console.log('Permissoes:', permissoes);

      //console.log('Fetching accões...');
      //await getAccoes();
      //console.log('Acções:', accoes);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.log("Utilizador não autenticado.");
      } else {
        console.log("Ocorreu um erro inesperado. Por favor, tente mais tarde.");
      }
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const validateEmail = (email) => {
    // Use a regular expression for basic email validation
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const submitForm = async () => {
    console.log("Clicou em submeter");

    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (name.length < 2) {
          setError("O nome deve ter pelo menos dois caracteres.");
          setLoading(false);

          // Show a error toast
          toast.error("O nome deve ter pelo menos dois caracteres.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }

        if (!validateEmail(email)) {
          setError("O email deve ser válido.");
          toggleLoading(false);

          // Show a error toast
          toast.error("O email deve ser válido.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }

        if (password.length < 6) {
          setError("A senha deve ter pelo menos seis caracteres.");
          toggleLoading(false);

          // Show a error toast
          toast.error("A senha deve ter pelo menos seis caracteres.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }

        if (password !== password2) {
          setError("As senhas não coincidem.");
          toggleLoading(false);

          // Show a error toast
          toast.error("As senhas não coincidem.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }

        // Basic form validation
        if (cargo_id == -1) {
          setError("deve definir um cargo para o utilizador.");
          setLoading(false);

          // Show a error toast
          toast.error("deve definir um cargo para o utilizador.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }

        if (departamento_id == -1) {
          setError("deve definir um departamento para o utilizador.");
          setLoading(false);

          // Show a error toast
          toast.error("deve definir um departamento para o utilizador.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }

        if (missao_id == -1) {
          setError("deve definir uma missão para o utilizador.");
          setLoading(false);

          // Show a error toast
          toast.error("deve definir uma missão para o utilizador.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });

          return;
        }

        await uploadUtilizador();
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Utilizador não autenticado.");

          // Show a error toast
          toast.error("Utilizador não autenticado.", {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          });
        } else {
          setError(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado"
          );

          // Show a error toast
          toast.error(
            "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
            {
              position: "top-right",
              autoClose: 3000, // Close the toast after 3 seconds
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: true,
            }
          );
        }
      } finally {
        toggleLoading(false);
      }
    } else {
      console.log("Clicou em submeter");

      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const uploadUtilizador = async () => {
    if (verificarPermissao("UTILIZADORES", "adicionar")) {
      try {
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
        formData.append("password", password);
        formData.append("nivel_id", nivel_id);
        formData.append("tipo", 3);
        formData.append("telefone", telefone);
        formData.append("fotografia", fotografia);

        formData.append("nif", nif);
        formData.append("nif_expira", nif_expira);

        formData.append("data_nasc", data_nasc);
        formData.append("genero", genero);
        formData.append("nivel_academico", nivel_academico);
        formData.append("endereco", endereco);
        formData.append("seg_social", seg_social);

        formData.append("cargo_id", cargo_id);
        formData.append("departamento_id", departamento_id);
        formData.append("missao_id", missao_id);

        formData.append("salario_coletivo", SalarioColetivo ? 1 : 2);
        formData.append("salario", salario);

        formData.append("admissao", admissao);
        formData.append("fim_contrato", fim_contrato);
        formData.append("inss", inss ? inss : 1);
        formData.append("irt", irt ? irt : 1);
        formData.append("expatriado", expatriado ? expatriado : 1);

        formData.append("estado", 0);
        formData.append("empresa_id", user.empresa_id);
        formData.append("missao_id", getMissao()?.id);

        const response = await http.post("/register", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        // Show a success toast
        toast.success(response.data.message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
          onClose: () => {
            // Redirecionar para a lista de utilizadores após o cadastro bem-sucedido
            //history.push('/dream-pos/users/userlists');

            setName("");
            setEmail("");
            setTelefone("");
            setPassword("");
            setPassword2("");

            triggerCloseButtonClick("closeButton1");

            onSubmissionSuccess();
          },
        });
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectAcessoChange = (id) => {
    setNivelSelecionado(id);
    setNivel(id);

    // Fetch new permissions after navigation
    getPermissoes(id);
  };

  /* permissoes */
  const getPermissoes = async (nivelSelecionado) => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("nivel_id", nivelSelecionado);

      /*
      const res = await http.post("/permissoes_errado", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      setPermissoes(res.data.permissoes);

      console.log("Permissões :");
      console.log(res.data.permissoes);

      */
    } catch (error) {
      console.error("Ocorreu um erro ao carregar os permissões:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = async (nivelId, acaoId, action) => {
    if (verificarPermissao("ACESSO", "atualizar")) {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append("nivel_id", nivelId);
        formData.append("accao_id", acaoId);
        formData.append("tipo", action);

        // Set the value based on the current state (toggle)
        const valor = permissoes_lista.some(
          (obj) =>
            obj.nivel_id === nivelId &&
            obj.accao_id === acaoId &&
            obj[action] === 1
        )
          ? 0
          : 1;
        formData.append("value", valor);

        console.log(nivelId, acaoId, action, valor);

        const res = await http.post("/update-permissao", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        console.log(res);

        if (res) {
          setPermissoes(res.data.permissoes);
        }
      } catch (error) {
        // Show a success toast
        toast.error("Erro ao atualizar permissão:", error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectRow = (index, accao_id) => {
    // Recupera o estado atual do checkbox "Selecionar tudo" da linha
    const selectAllChecked = document.querySelector(
      `.chk_selecionar_linhas_${index}`
    ).checked;

    console.log(accao_id);

    // Atualiza o estado de todos os checkboxes da linha com base no estado do "Selecionar tudo"
    document
      .querySelectorAll(`.chk_linha_permissao_${index}`)
      .forEach((checkbox) => {
        checkbox.checked = selectAllChecked;
        // Chame a função handleCheckboxChange para atualizar o estado no back-end, se necessário
        // Substitua os valores de acesso_id e cdata.id pelos valores corretos
      });

    const valor = selectAllChecked ? 1 : 0;

    console.log("Valor todos :");
    console.log(valor);

    handleAtivarDesativarLinha(nivelSelecionado, accao_id, valor);
  };

  const handleAtivarDesativarLinha = async (acesso_id, accao_id, valor) => {
    if (verificarPermissao("ACESSO", "atualizar")) {
      try {
        setLoading(true);

        const formData = new FormData();
        formData.append("nivel_id", acesso_id);
        formData.append("accao_id", accao_id);
        formData.append("valor", valor);

        console.log(acesso_id, accao_id, valor);

        const res = await http.post("/update-permissao-multipla", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });

        console.log(res);

        if (res) {
          setPermissoes(res.data.permissoes);
        }
      } catch (error) {
        // Show a success toast
        toast.error("Erro ao atualizar permissão:", error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const handleSelectedAll = async () => {
    if (verificarPermissao("ACESSO", "atualizar")) {
      setSelectAllChecked(!selectAllChecked);

      const checkboxes = document.querySelectorAll(".chk_selecionar_linhas");

      try {
        setLoading(true);

        for (let i = 0; i < checkboxes.length; i++) {
          const checkbox = checkboxes[i];

          // Defina o valor para todos os checkboxes (verificar ou desmarcar)
          checkbox.checked = !selectAllChecked;

          const accao_id = checkbox.getAttribute("code");
          const valor = !selectAllChecked ? 1 : 0;

          const formData = new FormData();

          formData.append("nivel_id", nivelSelecionado);
          formData.append("accao_id", accao_id);
          formData.append("valor", valor);

          const res = await http.post("/update-permissao-multipla", formData, {
            headers: { "Content-Type": "multipart/form-data" },
          });

          // Atualize o estado apenas com a última resposta
          if (i === checkboxes.length - 1) {
            const ultima_permissao = res.data.permissoes;
            setPermissoes(ultima_permissao);
          }
        }
      } catch (error) {
        // Mostrar um toast de erro
        toast.error("Erro ao atualizar permissão:", error, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: true,
        });
      } finally {
        setLoading(false);
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const renderOptionCargos = (cargos) => {
    return cargos?.map((cargo) => (
      <React.Fragment key={cargo.id}>
        <option value={cargo.id}>{cargo.designacao}</option>
      </React.Fragment>
    ));
  };

  const renderOptionDepartamentos = (departamentos) => {
    return departamentos?.map((departamento) => (
      <React.Fragment key={departamento.id}>
        <option value={departamento.id}>{departamento.designacao}</option>
      </React.Fragment>
    ));
  };

  const renderOptionMissoes = (missoes) => {
    return missoes?.map((missao) => (
      <React.Fragment key={missao.id}>
        <option value={missao.id}>{missao.designacao}</option>
      </React.Fragment>
    ));
  };

  /* fim get permissões */

  return (
    <div id="add_user" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{t("Add Employee")}</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              id="closeButton1"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <hr className=""></hr>
          <div className="modal-body">
            <form>
              <div className="row">
                <div className="col-sm-8">
                  <div className="input-block mb-3 mb-3 form-focus focused">
                    <label className="focus-label">
                      {t("Name")} <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="input-block mb-3 mb-3 form-focus focused">
                    <label className="focus-label">
                      {t("Gender")} <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control select"
                      onChange={(e) => setGenero(e.target.value)}
                    >
                      <option value="1">Feminino</option>
                      <option value="2">Masculino</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="input-block mb-3 mb-3 form-focus focused">
                    <label className="focus-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="input-block mb-3 mb-3 form-focus focused">
                    <label className="focus-label">
                      {t("phone")} <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={telefone}
                      onChange={(e) => setTelefone(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="input-block mb-3 mb-3 form-focus focused">
                    <label className="focus-label">
                      Bilhete / NIF <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={nif}
                      onChange={(e) => setNif(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="input-block mb-3 mb-3 form-focus focused">
                    <label className="focus-label">
                      BI/NIF VALIDADE <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      value={nif_expira}
                      onChange={(e) => setNifExpira(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="input-block mb-3 form-focus focused">
                    <label className="focus-label">{t("Data Admissão")} </label>
                    <input
                      className="form-control"
                      type="date"
                      value={admissao}
                      onChange={(e) => setAdmissao(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-sm-3">
                  <div className="input-block mb-3 form-focus focused">
                    <label className="focus-label">
                      {t("Fim de Contrato")}{" "}
                    </label>
                    <input
                      className="form-control"
                      type="date"
                      value={fim_contrato}
                      onChange={(e) => setFimContrato(e.target.value)}
                    />
                  </div>
                </div>

                {nivelSelecionado == "nada mesmo" ? (
                  <>
                    <div className="col-sm-6">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <label className="focus-label">
                          {t("User Type")}{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <select
                          className="form-control select"
                          onChange={(e) => setTipo(e.target.value)}
                        >
                          {user.tipo == 1 ? (
                            <option value="1">Super Utilizador</option>
                          ) : (
                            ""
                          )}

                          <option value="2">Admininistrador</option>
                          <option value="3" selected>
                            Colaborador
                          </option>
                        </select>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                {nivelSelecionado == "nada mesmo" ? (
                  <>
                    <div className="col-sm-6">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <label className="focus-label">{t("Password")}</label>
                        <input
                          className="form-control"
                          type="password"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <label className="focus-label">
                          {t("Confirm Password")}
                        </label>
                        <input
                          className="form-control"
                          type="password"
                          value={password2}
                          onChange={(e) => setPassword2(e.target.value)}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="col-sm-6">
                  <div className="input-block mb-3 mb-3 form-focus focused">
                    <label className="focus-label">{t("Job Role")}</label>
                    <select
                      onChange={(e) =>
                        handleSelectCargoChangeSalario(e.target.value)
                      }
                      value={cargo_id}
                      className="form-control select"
                    >
                      <option key={0} value={-1}></option>
                      {renderOptionCargos(cargos)}
                    </select>
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="input-block mb-3 mb-3 form-focus focused">
                    <label className="focus-label">{t("Department")}</label>
                    <select
                      onChange={(e) => setDepartamentoID(e.target.value)}
                      value={departamento_id}
                      className="form-control select"
                    >
                      <option key={0} value={-1}></option>
                      {renderOptionDepartamentos(departamentos)}
                    </select>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="input-block mt-4 mb-3 mb-3 form-focus focused">
                    <label className="focus-label">{t("Birthday")} </label>
                    <input
                      className="form-control"
                      type="date"
                      value={data_nasc}
                      onChange={(e) => setDataNasc(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-sm-8 mb-3 mb-3">
                  <div className="leave-inline-form ">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="carryForward"
                        id="carry_no_01"
                        checked={SalarioColetivo}
                        onChange={() => setSalarioColetivo(true)}
                      />

                      <label className="form-check-label" htmlFor="carry_no_01">
                        {t("Salário Coletivo Do Cargo")}
                      </label>
                    </div>

                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="carryForward"
                        id="carry_yes_01"
                        checked={!SalarioColetivo ? true : false}
                        onChange={() => setSalarioColetivo(false)}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="carry_yes_01"
                      >
                        {t("Salário individual")}
                      </label>
                    </div>
                    <div className="input-group">
                      <span className="input-group-text">{t("Valor")}</span>
                      <input
                        type="number"
                        className="form-control"
                        disabled={SalarioColetivo}
                        name="salario"
                        value={salario}
                        onChange={(e) => handleSalarioChanged(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="input-block mb-3 mb-3 form-focus focused">
                    <label className="focus-label">
                      {t("Expatriado ?")} <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control select"
                      onChange={(e) =>
                        handleSelectExpatriadoChange(e.target.value)
                      }
                      value={expatriado}
                    >
                      <option value="1">Não</option>
                      <option value="2">Sim</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="input-block mb-3 mb-3 form-focus focused">
                    <label className="focus-label">
                      {t("Sujeito a desconto INSS ?")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control select"
                      onChange={(e) => handleSelectINSSChange(e.target.value)}
                      value={inss}
                    >
                      <option value="1">Sim</option>
                      <option value="2">Não</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="input-block mb-3 mb-3 form-focus focused">
                    <label className="focus-label">
                      {t("Sujeito a desconto IRT ?")}{" "}
                      <span className="text-danger">*</span>
                    </label>
                    <select
                      className="form-control select"
                      onChange={(e) => handleSelectIRTChange(e.target.value)}
                      value={irt}
                    >
                      <option value="1">Sim</option>
                      <option value="2">Não</option>
                    </select>
                  </div>
                </div>

                {nivelSelecionado == "nada mesmo" ? (
                  <>
                    <div className="col-sm-8">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <label className="focus-label">{t("Mission")}</label>
                        <select
                          onChange={(e) => setMissaoID(e.target.value)}
                          value={missao_id}
                          className="form-control select"
                        >
                          <option key={0} value={-1}></option>
                          {renderOptionMissoes(missoes)}
                        </select>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="input-block mb-3 mb-3 form-focus focused">
                        <label className="focus-label">{t("Role")} </label>
                        <select
                          className="form-control select"
                          onChange={(e) =>
                            handleSelectAcessoChange(e.target.value)
                          }
                          value={nivelSelecionado}
                        >
                          {niveis?.map((cdata) => (
                            <option key={cdata.id} value={cdata.id}>
                              {cdata.designacao}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <div className="col-sm-12">
                  <div className="input-block mb-3 mb-3 form-focus focused">
                    <label className="focus-label">
                      {t("Nº Segurança Social")} <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={seg_social}
                      onChange={(e) => setSegurancaSocial(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="input-block mb-3 mb-3 form-focus focused">
                    <label className="focus-label">{t("Degree")} </label>
                    <input
                      className="form-control"
                      type="text"
                      value={nivel_academico}
                      onChange={(e) => setAcademico(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-sm-12">
                  <div className="input-block mb-3 mb-3 form-focus focused">
                    <label className="focus-label">
                      {t("Adress")} <span className="text-danger">*</span>
                    </label>
                    <input
                      className="form-control"
                      type="text"
                      value={endereco}
                      onChange={(e) => setEndereco(e.target.value)}
                    />
                  </div>
                </div>

                <div className="submit-section mb-5">
                  <a
                    className="btn btn-primary submit-btn"
                    onClick={submitForm}
                  >
                    {loading ? "Carregando" : t("Submit")}
                  </a>
                </div>
              </div>

              {nivelSelecionado == "nada mesmo" ? (
                <>
                  <div className="table-responsive m-t-15">
                    <label className="inputcheck">
                      {nivelSelecionado?.designacao}
                    </label>
                    &nbsp;
                    <label className="inputcheck">
                      <input
                        type="checkbox"
                        id="select-all"
                        onChange={() => handleSelectedAll()}
                        checked={selectAllChecked}
                      />
                      &nbsp; Selecionar tudo
                    </label>
                    <table className="table table-striped custom-table">
                      <thead>
                        <tr>
                          <th>{t("Module Permission")}</th>
                          <th className="text-center">{t("Read")}</th>
                          <th className="text-center">{t("Write")}</th>
                          <th className="text-center">{t("Create")}</th>
                          <th className="text-center">{t("Delete")}</th>
                          <th className="text-center">{t("Active")}</th>
                          <th className="text-center">{t("All")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {accoes_lista?.map((cdata, index) => {
                          // Find the corresponding object in your array
                          const matchingObject = permissoes_lista?.find(
                            (obj) => {
                              return (
                                obj.accao_id == cdata.id &&
                                obj.nivel_id == nivelSelecionado
                              );
                            }
                          );

                          return (
                            <tr key={index}>
                              <td>{cdata.designacao}</td>

                              <td className="text-center">
                                <label className="custom_check">
                                  <input
                                    key={cdata.id + "ver"}
                                    type="checkbox"
                                    checked={
                                      matchingObject && matchingObject.ver == 1
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(
                                        nivelSelecionado,
                                        cdata.id,
                                        "ver"
                                      )
                                    }
                                    className={"chk_linha_permissao_" + index}
                                  />
                                  <span className="checkmark" />
                                </label>
                              </td>

                              <td className="text-center">
                                <label className="custom_check">
                                  <input
                                    key={cdata.id + "atualizar"}
                                    type="checkbox"
                                    checked={
                                      matchingObject &&
                                      matchingObject.atualizar == 1
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(
                                        nivelSelecionado,
                                        cdata.id,
                                        "atualizar"
                                      )
                                    }
                                    className={"chk_linha_permissao_" + index}
                                  />
                                  <span className="checkmark" />
                                </label>
                              </td>

                              <td className="text-center">
                                <label className="custom_check">
                                  <input
                                    key={cdata.id + "adicionar"}
                                    type="checkbox"
                                    checked={
                                      matchingObject &&
                                      matchingObject.adicionar == 1
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(
                                        nivelSelecionado,
                                        cdata.id,
                                        "adicionar"
                                      )
                                    }
                                    className={"chk_linha_permissao_" + index}
                                  />
                                  <span className="checkmark" />
                                </label>
                              </td>

                              <td className="text-center">
                                <label className="custom_check">
                                  <input
                                    key={cdata.id + "remover"}
                                    type="checkbox"
                                    checked={
                                      matchingObject &&
                                      matchingObject.remover == 1
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(
                                        nivelSelecionado,
                                        cdata.id,
                                        "remover"
                                      )
                                    }
                                    className={"chk_linha_permissao_" + index}
                                  />
                                  <span className="checkmark" />
                                </label>
                              </td>

                              <td className="text-center">
                                <label className="custom_check">
                                  <input
                                    key={cdata.id + "ativar"}
                                    type="checkbox"
                                    checked={
                                      matchingObject &&
                                      matchingObject.ativar == 1
                                    }
                                    onChange={() =>
                                      handleCheckboxChange(
                                        nivelSelecionado,
                                        cdata.id,
                                        "ativar"
                                      )
                                    }
                                    className={"chk_linha_permissao_" + index}
                                  />
                                  <span className="checkmark" />
                                </label>
                              </td>

                              <td className="text-center">
                                <label className="custom_check">
                                  <input
                                    type="checkbox"
                                    checked={
                                      matchingObject &&
                                      matchingObject.ver == 1 &&
                                      matchingObject.adicionar &&
                                      matchingObject.atualizar &&
                                      matchingObject.ativar &&
                                      matchingObject.remover
                                    }
                                    key={cdata.id + "select"}
                                    onChange={() =>
                                      handleSelectRow(index, cdata.id)
                                    }
                                    className={
                                      "chk_selecionar_linhas_" +
                                      index +
                                      " chk_selecionar_linhas"
                                    }
                                    code={cdata.id}
                                  />
                                  <span className="checkmark" />
                                </label>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </>
              ) : (
                <></>
              )}
            </form>
          </div>
        </div>
      </div>

      <ToastContainer />

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AddColaboradorModal;
