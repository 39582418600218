import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Avatar_02,
  Avatar_03,
  Avatar_04,
  Avatar_05,
  Avatar_08,
  Avatar_09,
  Avatar_10,
  Avatar_11,
  Avatar_12,
  Avatar_13,
  Avatar_19,
} from "../../../Routes/ImagePath";

import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EmployeeListFilter from "../../../components/EmployeeListFilter";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../components/Spinner";
import AddColaboradorModal from "../../../components/Administration/Users/AddColaboradorModal";
import UserFilter from "../Administration/Users/UserFilter";
import EditColaboradorModal from "../../../components/Administration/Users/EditColaboradorModal";

import { startOfMonth, endOfMonth, format } from "date-fns";
import ModalFicheiroExcelColaborador from "../../../components/modelpopup/ModalFicheiroExcelColaborador";
import InfoPessoalModal from "../../../components/modelpopup/InfoPessoalModal";
import ContactoEmergenciaModal from "../../../components/modelpopup/ContactoEmergenciaModal";
import FilhosModal from "../../../components/modelpopup/FilhosModal";
import ExperienciaModal from "../../../components/modelpopup/ExperienciaModal";
import FormacaoModal from "../../../components/modelpopup/FormacaoModal";
import ContaBancariaModal from "../../../components/modelpopup/ContaBancariaModal";
import FechoDeContasModal from "../../../components/modelpopup/FechoDeContasModal";

import Swal from "sweetalert2";
import ModalFicheiroExcelFerias from "../../../components/modelpopup/ModalFicheiroExcelFerias";

const AllEmployee = () => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } =
    AuthUser();
  const [utilizadores, setUtilizador] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [missoes, setMissoes] = useState([]);
  const [accoes, setAccoes] = useState([]);
  const [utilizadorSelecionado, setUtilizadorSelecionado] = useState([]);

  const [utilizador, setUtilizadorInfo] = useState(0);

  const [filteredUsers, setFilteredUsers] = useState([]);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const handleSelect = (employee) => {
    setSelectedEmployees((prevSelected) => {
      if (prevSelected.includes(employee.id)) {
        return prevSelected.filter((id) => id !== employee.id);
      } else {
        return [...prevSelected, employee.id];
      }
    });
  };

  // Callback function to receive filtered users from UserFilter
  const handleFilterSubmit = (filteredUsers) => {
    setFilteredUsers(filteredUsers);
    setUtilizador(filteredUsers);
  };

  const handleSubmissionSuccess = () => {
    // Refresh acessos state or trigger any necessary actions
    setUtilizador([]);
    setFilteredUsers([]);
  };

  const getUtilizador = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/utilizadores/" + getMissao()?.id);

      setUtilizador(res.data.utilizadores);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getCargos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/cargos_ativos/" + getMissao()?.id);

      setCargos(res.data.cargos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getDepartamentos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/departamentos_ativos/" + getMissao()?.id);

      setDepartamentos(res.data.departamentos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getMissoes = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/missoes_ativas");

      setMissoes(res.data.missoes);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getAccoes = async () => {
    try {
      setLoading(true);

      const res = await http.get("/accoes");

      setAccoes(res.data.accoes);

      console.log("Acções");
      console.log(res.data.accoes);
    } catch (error) {
      console.error("Ocorreu um erro ao carregar os acções:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()

        // await getUtilizador();

        await getAccoes();

        await getCargos();
        await getDepartamentos();
        await getMissoes();
      } catch (error) {
        console.error("Erro ao obter utilizadores:", error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  const chamarModalEliminar = (idSelecionado) => {
    setUtilizadorSelecionado(idSelecionado);

    const editButton = document.getElementById("triggerModalEliminar");

    console.log("Utilizador selecionado:");
    console.log(utilizadorSelecionado);

    if (editButton) {
      editButton.click();
    }
  };

  const submitedSuccesfully = (colaborador) => {
    //nada mesmo
  };

  const chamarModalEdit = (idSelecionado) => {
    setUtilizadorSelecionado(idSelecionado);

    const editButton = document.getElementById("triggerModalEdit");

    console.log("Utilizador selecionado:");
    console.log(utilizadorSelecionado);

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalContaBancaria = (idSelecionado) => {
    setUtilizadorInfo(idSelecionado);

    const editButton = document.getElementById("chamar_modal_conta_bancaria");

    console.log("Utilizador selecionado:");
    console.log(utilizadorSelecionado);

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalfechoDeConta = (idSelecionado) => {
    setUtilizadorInfo(idSelecionado);

    const editButton = document.getElementById("chamar_modal_fecho_de_conta");

    console.log("Utilizador selecionado:");
    console.log(utilizadorSelecionado);

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalinfoPessoal = (idSelecionado) => {
    setUtilizadorInfo(idSelecionado);

    const editButton = document.getElementById("chamar_modal_info_pessoal");

    console.log("Utilizador selecionado:");
    console.log(utilizadorSelecionado);

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalContactoEmergencia = (idSelecionado) => {
    setUtilizadorInfo(idSelecionado);

    const editButton = document.getElementById(
      "chamar_modal_contacto_emergencia"
    );

    console.log("Utilizador selecionado:");
    console.log(utilizadorSelecionado);

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalFilhos = (idSelecionado) => {
    setUtilizadorInfo(idSelecionado);

    const editButton = document.getElementById("chamar_modal_filhos_modal");

    console.log("Utilizador selecionado:");
    console.log(utilizadorSelecionado);

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalExperiencias = (idSelecionado) => {
    setUtilizadorInfo(idSelecionado);

    const editButton = document.getElementById("chamar_modal_experiencias");

    console.log("Utilizador selecionado:");
    console.log(utilizadorSelecionado);

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalFormacao = (idSelecionado) => {
    setUtilizadorInfo(idSelecionado);

    const editButton = document.getElementById("chamar_modal_formacao_modal");

    console.log("Utilizador selecionado:");
    console.log(utilizadorSelecionado);

    if (editButton) {
      editButton.click();
    }
  };

  const ativarColaborador = async (id, newState) => {
    //console.log('Clicou aqui para ativar e desativar');

    if (verificarPermissao("UTILIZADORES", "ativar")) {
      Swal.fire({
        title: "Confirmar",
        text: "Tem a certeza que pretende voltar a ativar este colaborador ?",
        type: "confirm",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (t) {
        //the start

        if (t.isConfirmed) {
          try {
            const formData = new FormData();
            formData.append("id", id);
            formData.append("estado", newState);

            const response = await http.post("/utilizador/activate", formData, {
              headers: { "Content-Type": "multipart/form-data" },
            });

            toast.success(response.data.message, {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } catch (error) {
            console.error(error);

            toast.error(
              "Ocorreu um erro ao atualizar o estado do usuário. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }
        }

        //then ends
      });
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const verFechoDeConta = async (colaborador) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("user_id", colaborador?.id);

      const response = await http.post("/fecho-conta/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      setLoading(false);
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const getTotalColaboradores = () => {
    return utilizadores?.length;
  };

  const getTotalHomens = () => {
    return utilizadores?.filter((utilizador) => utilizador?.genero == 2)
      ?.length;
  };

  const getTotalMulheres = () => {
    return utilizadores?.filter((utilizador) => utilizador?.genero == 1)
      ?.length;
  };

  const getTotalExpatriados = () => {
    return utilizadores?.filter((utilizador) => utilizador?.expatriado == 2)
      ?.length;
  };

  if (verificarPermissao("UTILIZADORES", "ver"))
    if (loading)
      return (
        <>
          <Spinner></Spinner>
        </>
      );
    else
      return (
        <>
          (
          <>
            <div>
              <div className="page-wrapper">
                <div className="content container-fluid">
                  <Breadcrumbs
                    maintitle={t("Employees")}
                    title="Dashboard"
                    subtitle={t("Employee")}
                    modal="#add_user"
                    name={t("Add Employee")}
                    Linkname="/employees"
                    Linkname1="/employees-list"
                  />

                  <div className="row">
                    <div className="col-md-12">
                      <div className="card-group m-b-30">
                        {/* Total */}
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between mb-3">
                              <div>
                                <span className="d-block">
                                  {t("Colaboradores")}
                                </span>
                              </div>
                              <div>
                                <span className={"text-dark"}>
                                  {Math.round(
                                    (100 * getTotalColaboradores()) /
                                      getTotalColaboradores()
                                  )}
                                  %
                                </span>
                              </div>
                            </div>
                            <h3 className="mb-3">
                              {formatNumber(getTotalColaboradores())}
                            </h3>
                            <div
                              className="progress mb-2"
                              style={{ height: "5px" }}
                            >
                              <div
                                className="progress-bar bg-dark"
                                role="progressbar"
                                style={{
                                  width: `${Math.round(
                                    (100 * getTotalColaboradores()) /
                                      getTotalColaboradores()
                                  )}%`,
                                }}
                                aria-valuenow={40}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Entrada */}
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between mb-3">
                              <div>
                                <span className="d-block">{t("Homens")}</span>
                              </div>
                              <div>
                                <span className={"text-primary"}>
                                  {Math.round(
                                    (100 * getTotalHomens()) /
                                      getTotalColaboradores()
                                  )
                                    ? Math.round(
                                        (100 * getTotalHomens()) /
                                          getTotalColaboradores()
                                      )
                                    : 0}
                                  %
                                </span>
                              </div>
                            </div>

                            <h3 className="mb-3">
                              {formatNumber(getTotalHomens())}
                            </h3>

                            <div
                              className="progress mb-2"
                              style={{ height: "5px" }}
                            >
                              <div
                                className="progress-bar bg-primary"
                                role="progressbar"
                                style={{
                                  width: `${Math.round(
                                    (100 * getTotalHomens()) /
                                      getTotalColaboradores()
                                  )}%`,
                                }}
                                aria-valuenow={40}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Saidas */}
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between mb-3">
                              <div>
                                <span className="d-block">{t("Mulheres")}</span>
                              </div>
                              <div>
                                <span className={"text-warning"}>
                                  {Math.round(
                                    (100 * getTotalMulheres()) /
                                      getTotalColaboradores()
                                  )
                                    ? Math.round(
                                        (100 * getTotalMulheres()) /
                                          getTotalColaboradores()
                                      )
                                    : 0}
                                  %
                                </span>
                              </div>
                            </div>
                            <h3 className="mb-3">
                              {formatNumber(getTotalMulheres())}
                            </h3>
                            <div
                              className="progress mb-2"
                              style={{ height: "5px" }}
                            >
                              <div
                                className="progress-bar bg-warning"
                                role="progressbar"
                                style={{
                                  width: `${Math.round(
                                    (100 * getTotalMulheres()) /
                                      getTotalColaboradores()
                                  )}%`,
                                }}
                                aria-valuenow={40}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                        </div>

                        {/* Saidas */}
                        <div className="card">
                          <div className="card-body">
                            <div className="d-flex justify-content-between mb-3">
                              <div>
                                <span className="d-block">
                                  {t("Expatriados")}
                                </span>
                              </div>
                              <div>
                                <span className={"text-primary"}>
                                  {Math.round(
                                    (100 * getTotalExpatriados()) /
                                      getTotalColaboradores()
                                  )
                                    ? Math.round(
                                        (100 * getTotalExpatriados()) /
                                          getTotalColaboradores()
                                      )
                                    : 0}
                                  %
                                </span>
                              </div>
                            </div>
                            <h3 className="mb-3">
                              {formatNumber(getTotalExpatriados())}
                            </h3>
                            <div
                              className="progress mb-2"
                              style={{ height: "5px" }}
                            >
                              <div
                                className="progress-bar bg-primary"
                                role="progressbar"
                                style={{
                                  width: `${Math.round(
                                    (100 * getTotalExpatriados()) /
                                      getTotalColaboradores()
                                  )}%`,
                                }}
                                aria-valuenow={40}
                                aria-valuemin={0}
                                aria-valuemax={100}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <UserFilter
                    onFilterSubmit={handleFilterSubmit}
                    tipo={3}
                    departamentos={departamentos}
                    cargos={cargos}
                    utilizadoresSelecionados={selectedEmployees}
                  />

                  <div className="row">
                    {(filteredUsers.length > 0
                      ? filteredUsers
                      : utilizadores
                    )?.map((employee) => (
                      <div
                        className={`col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3`}
                        key={employee.id}
                      >
                        <div
                          className={`profile-widget selectable ${
                            selectedEmployees.includes(employee.id)
                              ? "selected"
                              : ""
                          }  ${employee?.estado != 1 ? " desativado " : ""}`}
                          onClick={() => handleSelect(employee)}
                        >
                          <div className="profile-img">
                            <Link
                              to={"/profile/" + employee.id}
                              className="avatar"
                            >
                              <img
                                src={
                                  url_root + "/storage/" + employee.fotografia
                                }
                                alt=""
                              />
                            </Link>
                          </div>
                          <div className="dropdown profile-action">
                            <Link
                              to="#"
                              className="action-icon dropdown-toggle"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <i className="material-icons">more_vert</i>
                            </Link>
                            <div className="dropdown-menu dropdown-menu-right">
                              <span
                                className="dropdown-item"
                                title={employee?.name}
                                onClick={() => chamarModalEdit(employee)}
                              >
                                <i className="fa fa-pencil m-r-5" /> {t("Edit")}
                              </span>
                              <span
                                className="dropdown-item"
                                title={employee?.name}
                                onClick={() =>
                                  chamarModalfechoDeConta(employee)
                                }
                              >
                                <i className="fa fa-file m-r-5" />{" "}
                                {t("Encerrar contrato")}
                              </span>

                              {employee?.estado != 1 ? (
                                <>
                                  <span
                                    className="dropdown-item"
                                    title={employee?.name}
                                    onClick={() =>
                                      ativarColaborador(
                                        employee?.id,
                                        employee?.estado == 1 ? 0 : 1
                                      )
                                    }
                                  >
                                    <i className="fa fa-check m-r-5" />{" "}
                                    {t("Ativar")}
                                  </span>

                                  <span
                                    className="dropdown-item"
                                    title={employee?.name}
                                    onClick={() => verFechoDeConta(employee)}
                                  >
                                    <i className="fa fa-file-pdf m-r-5 text-danger" />{" "}
                                    {t("Exportar fecho de conta")}
                                  </span>

                                  <span
                                    className="dropdown-item"
                                    title={employee?.name}
                                    onClick={() =>
                                      chamarModalEliminar(employee)
                                    }
                                  >
                                    <i className="fa fa-trash m-r-5" />{" "}
                                    {t("Delete")}
                                  </span>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </div>
                          <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                            <Link to={"/profile/" + employee.id}>
                              {employee.name}
                            </Link>
                          </h4>
                          <div className="small text-muted">
                            {employee?.cargo?.designacao}
                          </div>
                          <div className="row bg-light mt-2">
                            <div
                              className="col-sm-2 p-2"
                              title="Contas bancarias"
                            >
                              <span
                                className="text-primary"
                                onClick={() =>
                                  chamarModalContaBancaria(employee)
                                }
                              >
                                <i className="fa fa-bank"></i>
                              </span>
                            </div>

                            <div
                              className="col-sm-2 p-2"
                              title="Formações Profissionais e academicas"
                            >
                              <span
                                className="text-primary"
                                onClick={() => chamarModalFormacao(employee)}
                              >
                                <i className="fa fa-school"></i>
                              </span>
                            </div>

                            <div className="col-sm-2 p-2" title="Filhos">
                              <span
                                className="text-primary"
                                onClick={() => chamarModalFilhos(employee)}
                              >
                                <i className="fa fa-child"></i>
                              </span>
                            </div>

                            <div
                              className="col-sm-2 p-2"
                              title="Informação pessoal"
                            >
                              <span
                                className="text-primary"
                                onClick={() => chamarModalinfoPessoal(employee)}
                              >
                                <i className="fa fa-info-circle"></i>
                              </span>
                            </div>

                            <div className="col-sm-2 p-2" title="Experiência">
                              <span
                                className="text-primary"
                                onClick={() =>
                                  chamarModalExperiencias(employee)
                                }
                              >
                                <i className="fa fa-user-tie"></i>
                              </span>
                            </div>

                            <div
                              className="col-sm-2 p-2"
                              title="Contacto Emergência"
                            >
                              <span
                                className="text-danger"
                                onClick={() =>
                                  chamarModalContactoEmergencia(employee)
                                }
                              >
                                <i className="fa fa-phone"></i>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <AddColaboradorModal
                onSubmissionSuccess={handleSubmissionSuccess}
                accoes={accoes}
                cargos={cargos}
                departamentos={departamentos}
                missoes={missoes}
              />

              <EditColaboradorModal
                onSubmissionSuccess={handleSubmissionSuccess}
                utilizador={utilizadorSelecionado}
                generoParam={utilizadorSelecionado?.genero}
                nivelParam={utilizadorSelecionado?.nivel_id}
                accoes={accoes}
                cargos={cargos}
                departamentos={departamentos}
                missoes={missoes}
                imagePreview={
                  url_root + "/storage/" + utilizadorSelecionado?.fotografia
                }
              />

              <DeleteModal
                objectoName={t("Employee")}
                onSubmissionSuccess={handleSubmissionSuccess}
                id={utilizadorSelecionado?.id}
                endpoint={"utilizador"}
              />

              <ModalFicheiroExcelColaborador
                onSubmissionSuccess={handleSubmissionSuccess}
              />

              <ModalFicheiroExcelFerias
                onSubmissionSuccess={handleSubmissionSuccess}
              />

              <InfoPessoalModal
                colaborador_id={utilizador?.id}
                onSubmitSuccess={submitedSuccesfully}
              />

              <ContactoEmergenciaModal
                colaborador_id={utilizador?.id}
                onSubmitSuccess={submitedSuccesfully}
              />

              <FilhosModal
                colaborador_id={utilizador?.id}
                onSubmitSuccess={submitedSuccesfully}
              />

              <ExperienciaModal
                colaborador_id={utilizador?.id}
                onSubmitSuccess={submitedSuccesfully}
              />

              <ContaBancariaModal
                colaborador_id={utilizador?.id}
                onSubmitSuccess={submitedSuccesfully}
              />

              <FormacaoModal
                colaborador_id={utilizador?.id}
                onSubmitSuccess={submitedSuccesfully}
              />

              <FechoDeContasModal colaborador={utilizador} />

              <span
                data-bs-toggle="modal"
                data-bs-target="#fecho_de_conta_modal"
                id="chamar_modal_fecho_de_conta"
              ></span>

              <span
                data-bs-toggle="modal"
                data-bs-target="#conta_bancaria_modal"
                id="chamar_modal_conta_bancaria"
              ></span>

              <span
                data-bs-toggle="modal"
                data-bs-target="#formacao_modal"
                id="chamar_modal_formacao_modal"
              ></span>

              <span
                data-bs-toggle="modal"
                data-bs-target="#filhos_modal"
                id="chamar_modal_filhos_modal"
              ></span>

              <span
                data-bs-toggle="modal"
                data-bs-target="#contacto_emergencia_modal"
                id="chamar_modal_contacto_emergencia"
              ></span>

              <span
                data-bs-toggle="modal"
                data-bs-target="#personal_info_modal"
                id="chamar_modal_info_pessoal"
              ></span>

              <span
                data-bs-toggle="modal"
                data-bs-target="#experiencias_modal"
                id="chamar_modal_experiencias"
              ></span>

              <span
                data-bs-toggle="modal"
                data-bs-target="#edit_user"
                id="triggerModalEdit"
              ></span>

              <span
                data-bs-toggle="modal"
                data-bs-target="#delete"
                id="triggerModalEliminar"
              ></span>
            </div>
          </>
          );{" "}
        </>
      );
  else
    return (
      <>
        <div className="page-wrapper cardhead">
          <div className="content ">
            {/* Page Header */}
            <div className="page-header">
              <div className="row">
                <div className="col-sm-12">
                  <h3 className="page-title">SEM PERMISSÃO</h3>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h4 className="card-title">
                      <i className="fa fa-times-circle text-danger"></i>&nbsp;
                      OPERAÇÃO NÃO AUTORIZADA
                    </h4>
                  </div>
                  <div className="card-body">
                    <p>
                      Esta operação não é autorizada para o seu nivel de acesso
                      ao sistema, por favor contacte o administrador do sistema.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
};

export default AllEmployee;
