import React, { useState, useEffect } from "react";
import { Avatar_09, Avatar_10, Avatar_16 } from "../../Routes/ImagePath";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthUser from "../AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../Spinner";
import Swal from "sweetalert2";

const AddColaboradorFolhaSalarialModal = ({
  onSubmissionSuccess,
  utilizadores,
  salarios,
  processados,
  folha,
}) => {
  const { t } = useTranslation();
  const { http, url_root, user, user_permissoes, verificarPermissao } = AuthUser();

  const [trocar, setTrocarDeFolhaSalarial] = useState(0);
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedUsers, setSelectedUsers] = useState([]);

  useEffect(() => {
    setUsers(utilizadores);
    setFilteredUsers(utilizadores);
  }, [utilizadores]);

  const handleSearchTermChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    const filtered = users.filter((user) =>
      user.name.toLowerCase().includes(term.toLowerCase())
    );
    setFilteredUsers(filtered);
  };

  const handleUserSelect = (selectedUser) => {
    setSelectedUsers((prevSelectedUsers) => {
      const isAlreadySelected = prevSelectedUsers.some(
        (user) => user.id === selectedUser.id
      );

      if (isAlreadySelected) {
        return prevSelectedUsers.filter((user) => user.id !== selectedUser.id);
      } else {
        return [
          ...prevSelectedUsers,
          { ...selectedUser, subFeria: 0, subNatal: 0 },
        ];
      }
    });
  };

  const handleSubFeriaSelect = (selectedUser) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.map((user) =>
        user.id === selectedUser.id
          ? { ...user, subFeria: user.subFeria === 1 ? 0 : 1 }
          : user
      )
    );
  };

  const handleSubNatalSelect = (selectedUser) => {
    setSelectedUsers((prevSelectedUsers) =>
      prevSelectedUsers.map((user) =>
        user.id === selectedUser.id
          ? { ...user, subNatal: user.subNatal === 1 ? 0 : 1 }
          : user
      )
    );
  };

  const isSelected = (userId) => {
    return selectedUsers.some((user) => user.id === userId);
  };

  const isInSalarios = (userId) => {
    return salarios.some((salario) => salario.colaborador_id === userId);
  };

  const isInProcessados = (userId) => {
    return processados.some((processado) => processado.colaborador_id === userId);
  };

  const getUserSubFeriaClass = (userId) => {
    const user = selectedUsers.find((user) => user.id === userId);
    return user && user.subFeria === 1 ? "bg-primary text-white" : "";
  };

  const getUserSubNatalClass = (userId) => {
    const user = selectedUsers.find((user) => user.id === userId);
    return user && user.subNatal === 1 ? "bg-primary text-white" : "";
  };

  const getUserClass = (userId) => {
    if (isSelected(userId)) {
      return "bg-primary text-white";
    }
    if (isInSalarios(userId)) {
      return "bg-success text-white";
    }
    if (isInProcessados(userId)) {
      return "bg-danger text-white";
    }
    return "";
  };

  const prepareArraySubNatal = () => {
    return selectedUsers.map((user) => user.subNatal);
  };

  const prepareArraySubFeria = () => {
    return selectedUsers.map((user) => user.subFeria);
  };

  const add_colaborador_na_folha = async () => {
    if (verificarPermissao("CARGO", "adicionar")) {
      if (folha?.paga !== 1) {
        if (selectedUsers.length > 0) {
          try {
            setLoading(true);

            const formData = new FormData();
            const subsidiosNatal = prepareArraySubNatal();
            const subsidiosFeria = prepareArraySubFeria();

            formData.append("folha_id", folha?.id);
            formData.append("trocar", trocar);
            formData.append("colaboradores", JSON.stringify(selectedUsers));
            formData.append("subsidiosNatal", JSON.stringify(subsidiosNatal));
            formData.append("subsidiosFeria", JSON.stringify(subsidiosFeria));

            console.log("Prepared natals: ", subsidiosNatal);
            console.log("Prepared ferias: ", subsidiosFeria);

            const response = await http.post(
              "/folha-salarial/add-colaborador",
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );

            setLoading(false);

            if (response?.data) {
              onSubmissionSuccess(response);
              triggerCloseButtonClick("closeButton5");
            }

          } catch (error) {
            setLoading(false);
            toast.error(
              "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }
        } else {
          toast.error("Deve selecionar pelo menos um colaborador.", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });
        }
      } else {
        toast.error(
          "Esta folha já está paga. Não poderá adicionar outro colaborador.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: false,
      });
    }
  };

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event("click"));
    }
  };


  return (
    <div>
      <div
        id="add_colaborador_folha"
        className="modal modal-lg custom-modal fade"
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                Pesquisar Colaborador - Para Adicionar Na Folha Salarial
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeButton5"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="input-group m-b-30">
                <input
                  placeholder={t("Search")}
                  className="form-control search-input"
                  type="text"
                  value={searchTerm}
                  onChange={handleSearchTermChange}
                />
                <span
                  className="btn btn-primary"
                  onClick={add_colaborador_na_folha}
                >
                  {t("Adicionar Selecionados")}
                </span>
              </div>
              <div className="input-block mb-3 form-focus focused">
                <div>
                  <select
                    className="form-control"
                    value={trocar}
                    onChange={(e) => setTrocarDeFolhaSalarial(e.target.value)}
                  >
                    <option value={0}>Não</option>
                    <option value={1}>Sim</option>
                  </select>
                  <label className="focus-label">
                    {t("Forçar inclusão removendo de outra folha")}
                  </label>
                </div>
              </div>
              <div>
                {loading ? (
                  <Spinner></Spinner>
                ) : (
                  filteredUsers.length > 0 && (
                    <ul className="chat-user-list">
                      {filteredUsers.map((user) => (
                        <li key={user.id}>
                          <span>
                            <div className="row" title={user?.name}>
                              <div
                                className={`col-sm-8`}
                                onClick={() => handleUserSelect(user)}
                              >
                                <div
                                  className={`chat-block d-flex ${getUserClass(
                                    user.id
                                  )}`}
                                >
                                  <span className="avatar">
                                    <img
                                      src={
                                        url_root +
                                        "/storage/" +
                                        user?.fotografia
                                      }
                                      alt={user?.name}
                                      title={user?.name}
                                    />
                                  </span>
                                  <div className="media-body align-self-center text-nowrap">
                                    <div className="user-name">
                                      {user?.name}
                                    </div>
                                    <span className="designation">
                                      {user?.cargo?.designacao}
                                    </span>
                                  </div>
                                </div>
                              </div>

                              <div
                                className={`col-sm-2 bg-white py-3 ${getUserSubFeriaClass(
                                  user.id
                                )}`}
                                onClick={() => handleSubFeriaSelect(user)}
                              >
                                <span className="">
                                  <i className="fa fa-umbrella"></i> Sub. Ferias
                                </span>
                              </div>

                              <div
                                className={`col-sm-2 bg-white py-3 ${getUserSubNatalClass(
                                  user.id
                                )}`}
                                onClick={() => handleSubNatalSelect(user)}
                              >
                                <span className="">
                                  <i className="fa fa-tree"></i> Sub. Natal
                                </span>
                              </div>
                            </div>
                          </span>
                        </li>
                      ))}
                    </ul>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default AddColaboradorFolhaSalarialModal;
