/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from "react";
import { Avatar_09, Avatar_10, Avatar_16 } from "../../Routes/ImagePath";
import { Link, useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";

import AuthUser from "../AuthUser";



import { format } from 'date-fns';

const VideoTutorialModalPopup = ({ video, titulo }) => {

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getToken, getMissao, user } = AuthUser();

  useEffect(() => {

  }, [video]);


  return (
    <div>
      <div id="modal_video" className="modal custom-modal fade modal-lg" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{titulo}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="trigger_close_modal_tarefas"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body center container">

              <video width="520" height="240" controls>
                <source src={video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>


            </div>
          </div>
        </div>
      </div>


    </div>
  );
};

export default VideoTutorialModalPopup;
