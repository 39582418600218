import React, { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { useTranslation } from "react-i18next";

const ClientsFilter = () => {
  const [focusedClientId, setFocusedClientId] = useState(false);
  const [inputValueClientId, setInputValueClientId] = useState("");
  const [focusedEmployeeName, setFocusedEmployeeName] = useState(false);
  const [inputValueEmployeeName, setInputValueEmployeeName] = useState("");

  const { t } = useTranslation();

  const handleLabelClickClientId = () => {
    setFocusedClientId(true);
  };

  const handleInputBlurClientId = () => {
    if (inputValueClientId === "") {
      setFocusedClientId(false);
    }
  };

  const handleInputChangeClientId = (e) => {
    const value = e.target.value;
    setInputValueClientId(value);
    if (value !== "" && !focusedClientId) {
      setFocusedClientId(true);
    }
  };

  const handleLabelClickEmployeeName = () => {
    setFocusedEmployeeName(true);
  };

  const handleInputBlurEmployeeName = () => {
    if (inputValueEmployeeName === "") {
      setFocusedEmployeeName(false);
    }
  };

  const handleInputChangeEmployeeName = (e) => {
    const value = e.target.value;
    setInputValueEmployeeName(value);
    if (value !== "" && !focusedEmployeeName) {
      setFocusedEmployeeName(true);
    }
  };
  const companies = [
    { value: 1, label: "Global Technologies" },
    { value: 2, label: "Delta InfoTech" },
  ];
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  return (
    <>
      <div className="row filter-row">
        <div className="col-sm-6 col-md-3">
          <div
            className={
              focusedClientId
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={inputValueClientId}
              onFocus={handleLabelClickClientId}
              onBlur={handleInputBlurClientId}
              onChange={handleInputChangeClientId}
            />
            <label className="focus-label" onClick={handleLabelClickClientId}>
            {t('Client ID')}
            </label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div
            className={
              focusedEmployeeName
                ? "input-block form-focus focused"
                : "input-block form-focus"
            }
          >
            <input
              type="text"
              className="form-control floating"
              value={inputValueEmployeeName}
              onFocus={handleLabelClickEmployeeName}
              onBlur={handleInputBlurEmployeeName}
              onChange={handleInputChangeEmployeeName}
            />
            <label
              className="focus-label"
              onClick={handleLabelClickEmployeeName}
            >
              {t('Client Name')}
            </label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="input-block form-focus select-focus">
            <Select
              options={companies}
              placeholder="Select Companies"
              styles={customStyles}
            />
            <label className="focus-label">{t('Company')}</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <Link to="#" className="btn btn-success btn-block w-100">
          {t('Search')}
          </Link>
        </div>
      </div>
    </>
  );
};

export default ClientsFilter;
