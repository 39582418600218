import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import AuthUser from "../../../components/AuthUser";
import Spinner from "../../../components/Spinner";
import PresencaFilter from "../Administration/Users/PresencaFilter";
import FeriaFilter from "../Administration/Users/FeriaFilter";

import { startOfMonth, endOfMonth, format } from "date-fns";

const FeriasTable = (dados) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } =
    AuthUser();

  const [loading, setLoading] = useState(false);
  const [feriasData, setFeriasData] = useState([]);
  const [ano, setAno] = useState(new Date().getFullYear());

  const [utilizadores, setUtilizador] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const [mes, setMes] = useState(1);

  const handleSelect = (employee) => {
    setSelectedEmployees((prevSelected) => {
      if (prevSelected.includes(employee.id)) {
        return prevSelected.filter((id) => id !== employee.id);
      } else {
        return [...prevSelected, employee.id];
      }
    });
  };


  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  // Callback function to receive filtered users from UserFilter
  const handleFilterSubmit = (filteredUsers) => {
    setFeriasData(filteredUsers);
    setFeriasData(filteredUsers);
  };

  const getUtilizador = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append("nome", "");
      formData.append("telefone", "");
      formData.append("email", "");
      formData.append("estado", -1);
      formData.append("tipo", -1);
      formData.append("missao_id", getMissao()?.id);

      formData.append("cargo_id", -1);
      formData.append("departamento_id", -1);

      formData.append("mes", mes);
      formData.append("ano", ano);

      const response = await http.post("/utilizador/presencas", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("Dados");
      console.log(response.data.dados);

      setUtilizador(response.data.dados);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getCargos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/cargos_ativos/" + getMissao()?.id);

      setCargos(res.data.cargos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getDepartamentos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/departamentos_ativos/" + getMissao()?.id);

      setDepartamentos(res.data.departamentos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  useEffect(() => {
    const currentDate = new Date();

    setAno(format(endOfMonth(currentDate), "yyyy"));

    setMes(format(endOfMonth(currentDate), "MM"));

    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()

        //await getUtilizador();

        await getCargos();
        await getDepartamentos();
      } catch (error) {
        console.error("Erro ao obter utilizadores:", error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  const renderFeriasCell = (feria) => {
    return (
      <>
        {feria.saida && (
          <strong style={{ color: "red" }}>
            {new Date(feria.saida).getDate()}
          </strong>
        )}
        {feria.regresso && (
          <>
          {" -> "}
          <strong style={{ color: "green" }}>
            {" "}{new Date(feria.regresso).getDate()}
          </strong> </>
        )}
        {
        (feria.saiu && feria.saiu == 1) ? (
            <>{" "}<span
            style={{
              backgroundColor: "green",
              color: "white",
              padding: "2px 5px",
              borderRadius: "3px",
            }}
          >
           Saiu
          </span></>):<></>
        
        }
      </>
    );
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <>
          <FeriaFilter
            onFilterSubmit={handleFilterSubmit}
            tipo={3}
            departamentos={departamentos}
            cargos={cargos}
            utilizadoresSelecionados={selectedEmployees}
          />
          <div className="table-responsive">
            <table className="table table-responsive table-striped mb-0">
              <thead>
                <tr>
                  <th>{t("Colaborador")}</th>
                  {[...Array(12).keys()].map((month) => (
                    <th key={month}>
                      {new Date(2021, month).toLocaleString("pt-BR", {
                        month: "long",
                      })}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {feriasData.map((item) => (
                  <tr key={item.user.id} className={` selectable ${
                    selectedEmployees.includes(item.user.id)
                      ? "selected"
                      : ""
                  }  ${item.user?.estado != 1 ? " desativado " : ""}`}
                  onClick={() => handleSelect(item.user)} >
                    <td style={{ textAlign: "left" }}>
                      <h2 className="table-avatar">
                        <Link
                          className="avatar avatar-xs"
                          to={"/profile/" + item.user?.id}
                        >
                          <img
                            alt=""
                            src={url_root + "/storage/" + item.user?.fotografia}
                          />
                        </Link>
                        <Link to={"/profile/" + item.user?.id}>
                          {item.user?.name}
                          
                        </Link>
                        
                      </h2>
                      
                    </td>
                    {[...Array(12).keys()].map((month) => (
                      <td key={month}>
                        {renderFeriasCell(item.ferias[month + 1])}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
    </>
  );
};

export default FeriasTable;
