/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";
import {
  Avatar_02,
  Avatar_16,
  User1,
  User2,
  User3,
  User4,
} from "../../../Routes/ImagePath";
import { Link, useNavigate, useParams } from "react-router-dom";
import ProfileTab from "./ProfileTab";
import Breadcrumbs from "../../../components/Breadcrumbs";

import { useTranslation } from "react-i18next";

import { format } from "date-fns";

import Swal from "sweetalert2";

import AuthUser from "../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../components/Spinner";
import EditUserModal from "../../../components/Administration/Users/EditUseModal";
import EditColaboradorModal from "../../../components/Administration/Users/EditColaboradorModal";
import AddColaboradorModal from "../../../components/Administration/Users/AddColaboradorModal";
import PesquisarColaboradorModal from "../../../components/modelpopup/PesquisarColaboradorModal";

const Profile = () => {
  const { t } = useTranslation();

  let { userID } = useParams();

  const [projetoSelecionado, setProjetoSelecionado] = useState();
  const [filteredProjects, setFilteredProjects] = useState([]);

  const [utilizadores, setUtilizadores] = useState([]);
  const [arquivos, setArquivos] = useState([]);
  const [utilizador, setUtilizador] = useState([]);
  const [faltas, setFaltas] = useState([]);
  const [ferias, setFerias] = useState([]);
  const [dividas, setDividas] = useState([]);
  const [faltasAnual, setFaltasAnual] = useState([]);
  const [feriasAnual, setFeriasAnual] = useState([]);
  const [missoes, setMissoes] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const {
    http,
    url_root,
    user_permissoes,
    verificarPermissao,
    getToken,
    user,
    getMissao,
  } = AuthUser();
  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [atividades, setAtividades] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [tarefas, setTarefas] = useState([]);
  const [accoes, setAccoes] = useState([]);

  const [salarios, setSalarios] = useState([]);
  const [salario, setSalario] = useState([]);
  const [agregados, setAgregados] = useState([]);

  const navigate = useNavigate();
  const [projetos, setProjetos] = useState([]);

  useEffect(() => {
    if (getToken() == undefined) {
      logout();
      navigate("/");
    } else {
      const fetchData = async () => {
        try {

          // Chama a função assíncrona getUtilizador()
          await getUtilizador();
          await getProjetos();
          await getUtilizadores();
          await getMissoes();
          await getTarefas();

          await getAccoes();
          await getCargos();
          await getDepartamentos();

          await getAtividades();
        } catch (error) {
          console.error("Erro ao obter utilizadores:", error);
        }
      };

      // Chama a função fetchData() para iniciar o processo
      fetchData();
    }
  }, [userID]);

  const handleNavegaPerfilColaborador = (colaborador_id) => {
    navigate("/profile/" + colaborador_id);
  };

  const handleSubmissionSuccessProjeto = () => {
    getProjetos();
  };

  const handleSubmissionSuccess = (mensagem) => {
    getUtilizador();
  };

  const onSubmissionUserSuccess = (colaborador, arquivos) => {
    setUtilizador(colaborador);
    setArquivos(arquivos);
  };

  const getUtilizador = async () => {
    try {

      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/utilizador/" + userID);
      
      setUtilizador(res?.data?.utilizador);
      setArquivos(res?.data?.arquivos);
      setFaltas(res?.data?.faltas);
      setDividas(res?.data?.dividas);
      setFaltasAnual(res?.data?.faltas_mensal);

      setSalario(res?.data?.salario);
      setAgregados(res?.data?.agregados);
      setSalarios(res?.data?.salarios);

      setLoading(false);

    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getTarefas = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/tarefas_todas");

      setTarefas(res.data.tarefas);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getMissoes = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/missoes_ativas");

      setMissoes(res.data.missoes);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getProjetos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/projetos/"+getMissao()?.id);

      setProjetos(res.data.projetos);

      console.log("projetos :");
      console.log(projetos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getUtilizadores = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/utilizadores/ativados/"+getMissao()?.id);

      setUtilizadores(res.data.utilizadores);

      console.log("Utilizadores : ");
      console.log(res.data.utilizadores);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getAtividades = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/utilizador/atividades/" + userID);

      setAtividades(res.data.atividades);

      console.log("atividades : ");
      console.log(res.data.atividades);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const chamarModalEdit = (idSelecionado) => {
    const editButton = document.getElementById("triggerModalEditUser");

    if (editButton) {
      editButton.click();
    }
  };

  const handleSubmissionSuccessUser = () => {
    const editButton = document.getElementById("closeButton2");

    if (editButton) {
      editButton.click();
    }

    getUtilizador();
  };

  const getCargos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/cargos_ativos/"+getMissao()?.id);

      setCargos(res.data.cargos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getDepartamentos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/departamentos_ativos/"+getMissao()?.id);

      setDepartamentos(res.data.departamentos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getAccoes = async () => {
    try {
      setLoading(true);

      const res = await http.get("/accoes");

      setAccoes(res.data.accoes);

      console.log("Acções");
      console.log(res.data.accoes);
    } catch (error) {
      console.error("Ocorreu um erro ao carregar os acções:", error);
    } finally {
      setLoading(false);
    }
  };

  if (loading)
    return (
      <>
        <Spinner></Spinner>
      </>
    );
  else
    return (
      <>
        <div className="page-wrapper">
          <div className="content container-fluid">
            <Breadcrumbs
              maintitle={t("Profile")}
              title="Dashboard"
              subtitle={t("Profile")}
              modal="#find_colaborador"
            />
            <div className="card mb-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="profile-view">
                      <div className="profile-img-wrap">
                        <div className="profile-img">
                          <Link to="#">
                            <img
                              className="rounded-pill"
                              src={
                                url_root + "/storage/" + utilizador?.fotografia
                              }
                              alt="User Image"
                            />
                          </Link>
                        </div>
                      </div>
                      <div className="profile-basic">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="profile-info-left">
                              <h3 className="user-name m-t-0 mb-0">
                                {utilizador?.name}
                              </h3>
                              <h6 className="text-muted">
                                {utilizador?.departamento?.designacao}
                              </h6>
                              <small className="text-muted">
                                {utilizador?.cargo?.designacao}
                              </small>

                              <div className="staff-id">
                                {t("Employee ID")} : {utilizador?.id}
                              </div>
                              <div className="small doj text-muted">
                                {t("Mission")} :{" "}
                                {utilizador?.missao?.designacao}
                              </div>

                              <div className="staff-id">
                                BI / NIF - Validade
                              </div>
                              <div className="small doj text-muted">
                                {utilizador?.nif} -{" "}
                                {utilizador?.nif_expira
                                  ? format(
                                      new Date(utilizador?.nif_expira),
                                      "dd/MM/yyyy"
                                    )
                                  : utilizador?.nif_expira}
                              </div>

                              <div className="staff-id">
                               Data Admissão
                              </div>
                              <div className="small doj text-muted">
                                {utilizador?.admissao
                                  ? format(
                                      new Date(utilizador?.admissao),
                                      "dd/MM/yyyy"
                                    )
                                  : utilizador?.admissao}
                              </div>

                              {utilizador?.id != user?.id ? (
                                <div className="staff-msg">
                                  <Link
                                    className="btn btn-custom"
                                    to={"/call/chat/1/" + utilizador?.id}
                                  >
                                    {t("Send Message")}
                                  </Link>
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="col-md-7">
                            <ul className="personal-info">
                              <li>
                                <div className="title">{t("Phone")}:</div>
                                <div className="text">
                                  <Link to={`tel:${utilizador?.telefone}`}>
                                    {utilizador?.telefone}
                                  </Link>
                                </div>
                              </li>
                              <li>
                                <div className="title">Email:</div>
                                <div className="text">
                                  <Link to={`mailto:${utilizador?.email}`}>
                                    {utilizador?.email}
                                  </Link>
                                </div>
                              </li>
                              <li>
                                <div className="title">{t("Birthday")}:</div>
                                <div className="text">
                                  {utilizador.data_nasc
                                    ? format(
                                        new Date(utilizador.data_nasc),
                                        "dd/MM/yyyy"
                                      )
                                    : utilizador.data_nasc}
                                </div>
                              </li>
                              <li>
                                <div className="title">{t("Address")}:</div>
                                <div className="text">
                                  {utilizador?.endereco}
                                </div>
                              </li>
                              <li>
                                <div className="title">{t("Gender")}:</div>
                                <div className="text">
                                  {utilizador?.genero == 1
                                    ? "Feminino"
                                    : "Masculino"}
                                </div>
                              </li>
                              <li>
                                {/* <div className="title">{t('Reports to')}:</div>
                              <div className="text">
                                <div className="avatar-box">
                                  <div className="avatar avatar-xs">
                                    <img src={Avatar_16} alt="User Image" />
                                  </div>
                                </div>
                                <Link to="profile">
                                  {userData.supervisor.name}
                                </Link>
                              </div> */}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="pro-edit">
                        <span
                          className="edit-icon"
                          onClick={() => chamarModalEdit(utilizador)}
                        >
                          <i className="fa-solid fa-pencil"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card tab-box">
              <div className="row user-tabs">
                <div className="col-lg-12 col-md-12 col-sm-12 line-tabs">
                  <ul className="nav nav-tabs nav-tabs-bottom">
                    <li className="nav-item">
                      <Link
                        to="#emp_profile"
                        data-bs-toggle="tab"
                        className="nav-link active"
                      >
                        <i className="fa fa-user"></i>{" "}
                        {t("Profile")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#emp_faltas"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        <i className="fa fa-calendar"></i>{" "}
                        {t("Faltas")}
                      </Link>
                    </li>
                   
                    <li className="nav-item">
                      <Link
                        to="#emp_dividas"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        <i className="la la-money text-danger"></i>{" "}
                        {t("Dividas")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#emp_salarios"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        <i className="la la-money text-success"></i>{" "}
                        {t("Salários")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#emp_ferias"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        <i className="fa fa-umbrella"></i>{" "}
                        {t("Ferias")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#emp_projects"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                         <i className="la la-rocket" />{" "}
                        {t("Projects")}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#bank_statutory"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                         <i className="fa fa-check" />{" "}
                        {t("Tasks")}
                        {/* <small className="text-danger ms-1">(Admin Only)</small> */}
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="#tab_activities"
                        data-bs-toggle="tab"
                        className="nav-link"
                      >
                        <i className="fa fa-list" />{" "}
                        {t("Activities")}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            {/* Profile Info Tab */}
            <ProfileTab
              projetos={projetos}
              tarefas={tarefas}
              utilizadores={utilizadores}
              arquivos={arquivos}
              missoes={missoes}
              utilizador={utilizador}
              faltas={faltas}
              ferias={ferias}
              dividas={dividas}
              faltasAnual={faltasAnual}
              feriasAnual={feriasAnual}
              atividades={atividades}
              salariosP={salarios}
              salarioP={salario}
              agregadosP={agregados}
              onSubmissionSuccess={handleSubmissionSuccessProjeto}
              onSubmissionUserSuccess={onSubmissionUserSuccess}
            />

            <PesquisarColaboradorModal
              onSubmissionSuccess={handleNavegaPerfilColaborador}
              utilizadores={utilizadores}
            />

            <EditColaboradorModal
              onSubmissionSuccess={handleSubmissionSuccess}
              utilizador={utilizador}
              generoParam={utilizador?.genero}
              nivelParam={utilizador?.nivel_id}
              accoes={accoes}
              cargos={cargos}
              departamentos={departamentos}
              missoes={missoes}
              imagePreview={url_root + "/storage/" + utilizador?.fotografia}
            />

            <AddColaboradorModal
              onSubmissionSuccess={handleSubmissionSuccess}
              accoes={accoes}
              cargos={cargos}
              departamentos={departamentos}
              missoes={missoes}
            />

            <span
              data-bs-toggle="modal"
              data-bs-target="#edit_user"
              id="triggerModalEditUser"
            ></span>
          </div>
        </div>
      </>
    );
};

export default Profile;
