import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { useTranslation } from "react-i18next";

import { startOfMonth, endOfMonth, format } from "date-fns";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../../components/Spinner";
import MarcarFaltaModal from "../../../../components/modelpopup/MarcarFaltaModal";
import MarcarFaltaConjuntaModal from "../../../../components/modelpopup/MarcarFaltaConjuntaModal";
import RegistarPagamentoFolhaSalarialConjuntaModal from "../../../../components/modelpopup/RegistarPagamentoFolhaSalarialConjuntaModal";

import Swal from "sweetalert2";

const FolhaSalarialFilter = ({
  onFilterSubmit,
  tipo,
  departamentos,
  cargos,
  postos,
  folhasSelecionadas,
}) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } =
    AuthUser();
  const [utilizadores, setUtilizador] = useState([]);

  const [ano, setAno] = useState(2024);
  const [mes, setMes] = useState(1);
  const [reporte, setReporte] = useState();

  const [estadoFilter, setEstadoFilter] = useState(-1);

  const [cargo_id, setCargoID] = useState(-1);
  const [banco_id, setBancoID] = useState(-1);
  const [posto_id, setPostoID] = useState(-1);
  const [departamento_id, setDepartamentoID] = useState(-1);
  const [pagamento, setPagamento] = useState(-1);

  const [setSelectedOption] = useState(null);
  const [setSelectedOptionTwo] = useState(null);

  const [itemFocus, setItemFocus] = useState(false);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  useEffect(() => {
    setReporte("pdf");

    const currentDate = new Date();

    setAno(format(endOfMonth(currentDate), "yyyy"));

    setMes(format(endOfMonth(currentDate), "MM"));
  }, []);

  const handleOnSubmitSuccess = () => {
    //onFilterSubmit();
  };

  const toggleDropdown = () => {
    setDropdownVisible((prevVisible) => !prevVisible);
  };

  const inputFocus = () => {
    setItemFocus(true);
  };

  const inputBlur = () => {
    setItemFocus(false);
  };

  const handleFilterSubmit = async () => {
    try {
      console.log("consultar");

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();

      formData.append("missao_id", getMissao()?.id);

      formData.append("cargo_id", cargo_id);
      formData.append("departamento_id", departamento_id);
      formData.append("posto_id", posto_id);
      formData.append("mes", mes);
      formData.append("ano", ano);
      formData.append("pagamento", pagamento);

      const response = await http.post("/folha-salarial/pesquisar", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log(response?.data?.folhas);

      // Update the parent component state with filtered users
      onFilterSubmit(response?.data?.folhas);

      setLoading(false);
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const chamarModalMarcarFaltaConjunta = () => {
    const editButton = document.getElementById("triggerMarcarFaltaConjunta");

    if (editButton) {
      editButton.click();
    }
  };

  const chamarAccaoEliminar = () => {
    handleEliminar();
  };

  const handleEliminar = async () => {
    Swal.fire({
      title: "Remover folhas salariais selecionadas?",
      text: "Apenas as folhas salarias não pagas serão removidas, pretende continuar com a remoção?",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {
      if (t.isConfirmed) {
        try {
          setLoading(true);

          try {
            const formData = new FormData();
            formData.append("folhas", JSON.stringify(folhasSelecionadas));

            const response = await http.post(
              "/folha-salarial/colectivo/eliminar",
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );

            setLoading(false);

            handleOnSubmitSuccess();

            // Atualize a lista de acessos após ativar/desativar
          } catch (error) {
            console.error(error);

            toast.error(
              "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          } finally {
            setLoading(false);
          }

          setLoading(false);
        } catch (error) {
          setLoading(false);

          toast.error(
            "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      }
    });
  };

  const submitFolhasSalarialPDFdistintas = async () => {
    try {
      setLoading(true);

      try {
        const formData = new FormData();
        formData.append("missao_id",getMissao()?.id);
        formData.append("folhas", JSON.stringify(folhasSelecionadas));

        const response = await http.post(
          "/folha_salarial/distintas/pdf",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        console.log("response", response.data.url);

        setLoading(false);

        // Open the response URL in a new tab
        window.open(response?.data?.url, "_blank");

        // Atualize a lista de acessos após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const submitFolhasSalarialPDFunificadas = async () => {
    try {
      setLoading(true);

      try {
        const formData = new FormData();
        formData.append("missao_id", getMissao()?.id);
        formData.append("folhas", JSON.stringify(folhasSelecionadas));

        const response = await http.post(
          "/folha_salarial/unificadas/pdf",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        console.log("response", response.data.url);

        setLoading(false);

        // Open the response URL in a new tab
        window.open(response?.data?.url, "_blank");

        // Atualize a lista de acessos após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const submitFolhasSalarialPDFmesAno = async () => {
    try {
      setLoading(true);

      try {
        const formData = new FormData();

        formData.append("mes", mes);
        formData.append("ano", ano);
        formData.append("missao_id",getMissao()?.id);

        const response = await http.post(
          "/folha_salarial/mes/ano/pdf",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        console.log("response", response.data.url);

        setLoading(false);

        // Open the response URL in a new tab
        window.open(response?.data?.url, "_blank");

        // Atualize a lista de acessos após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const submitFolhasSalarialExcelunificadas = async () => {
    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("missao_id",getMissao()?.id);
      formData.append("folhas", JSON.stringify(folhasSelecionadas));

      const response = await http.post(
        "/folha_salarial/unificadas/excel",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(url_root + "" + response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);
      toast.error(
        "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    } finally {
      setLoading(false);
    }
  };

  const submitFolhasSalarialExcelmesAno = async () => {
    try {
      setLoading(true);

      try {

        const formData = new FormData();

        formData.append("mes", mes);
        formData.append("ano", ano);
        formData.append("missao_id",getMissao()?.id);

        const response = await http.post(
          "/folha_salarial/mes/ano/excel",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        console.log("response", response.data.url);

        setLoading(false);

        // Open the response URL in a new tab
        window.open(url_root + "" + response?.data?.url, "_blank");

        // Atualize a lista de acessos após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const submitFolhasSalarialDetalhadaExcelmesAno = async () => {
    try {
      setLoading(true);

      try {

        const formData = new FormData();

        formData.append("mes", mes);
        formData.append("ano", ano);
        formData.append("missao_id",getMissao()?.id);

        const response = await http.post(
          "/folha_salarial/detalhada/mes/ano/excel",
          formData,
          {
            headers: { "Content-Type": "multipart/form-data" },
          }
        );

        console.log("response", response.data.url);

        setLoading(false);

        // Open the response URL in a new tab
        window.open(url_root + "" + response?.data?.url, "_blank");

        // Atualize a lista de acessos após ativar/desativar
      } catch (error) {
        console.error(error);

        toast.error(
          "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      } finally {
        setLoading(false);
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const renderOptionCargos = (cargos) => {
    return cargos?.map((cargo) => (
      <React.Fragment key={cargo.id}>
        <option value={cargo.id}>{cargo.designacao}</option>
      </React.Fragment>
    ));
  };

  const renderOptionDepartamentos = (departamentos) => {
    return departamentos?.map((departamento) => (
      <React.Fragment key={departamento.id}>
        <option value={departamento.id}>{departamento.designacao}</option>
      </React.Fragment>
    ));
  };

  const renderOptionPostos = (postos) => {
    return postos?.map((posto) => (
      <React.Fragment key={posto.id}>
        <option value={posto.id}>
          {posto?.cliente?.nome} - {posto.codigo} {posto.nome}
        </option>
      </React.Fragment>
    ));
  };

  return (
    <div className="row filter-row space">
      <div className="col-sm-6 col-md-1">
        <div className="input-block form-focus select-focus">
          <label className="focus-label">{t("Ano")}</label>
          <select
            onChange={(e) => setAno(e.target.value)}
            value={ano}
            className="form-control select"
          >
            <option value={-1}></option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
          </select>
        </div>
      </div>

      <div className="col-sm-6 col-md-1">
        <div className="input-block form-focus select-focus">
          <label className="focus-label">{t("Mês")}</label>

          <select
            onChange={(e) => setMes(e.target.value)}
            value={mes}
            className="form-control select"
          >
            <option value={-1}></option>
            <option value="1">Janeiro</option>
            <option value="2">Fevereiro</option>
            <option value="3">Março</option>
            <option value="4">Abril</option>
            <option value="5">Maio</option>
            <option value="6">Junho</option>
            <option value="7">Julho</option>
            <option value="8">Agosto</option>
            <option value="9">Setembro</option>
            <option value="10">Outubro</option>
            <option value="11">Novembro</option>
            <option value="12">Dezembro</option>
          </select>
        </div>
      </div>

      <div className="col-sm-6 col-md-2">
        <div className="input-block form-focus select-focus">
          <select
            className="form-control"
            onChange={(e) => setPagamento(e.target.value)}
            value={pagamento}
          >
            <option value="-1">-</option>
            <option value="2">Não Paga</option>
            <option value="1">Paga</option>
          </select>
          <label className="focus-label">{t("Pagamento")}</label>
        </div>
      </div>

      <div className="col-sm-6 col-md-2">
        <div className="input-block mb-3 form-focus focused">
          <label className="focus-label">{t("Department")}</label>
          <select
            onChange={(e) => setDepartamentoID(e.target.value)}
            value={departamento_id}
            className="form-control select"
          >
            <option key={0} value={-1}></option>
            {renderOptionDepartamentos(departamentos)}
          </select>
        </div>
      </div>

      <div className="col-sm-6 col-md-2">
        <div className="input-block mb-3 form-focus focused">
          <label className="focus-label">{t("Job Role")}</label>
          <select
            onChange={(e) => setCargoID(e.target.value)}
            value={cargo_id}
            className="form-control select"
          >
            <option key={0} value={-1}></option>
            {renderOptionCargos(cargos)}
          </select>
        </div>
      </div>

      <div className="col-sm-6 col-md-2">
        <div className="input-block mb-3 form-focus focused">
          <label className="focus-label">{t("Postos De Clientes")}</label>
          <select
            onChange={(e) => setPostoID(e.target.value)}
            value={posto_id}
            className="form-control select"
          >
            <option key={0} value={-1}></option>
            {renderOptionPostos(postos)}
          </select>
        </div>
      </div>

      <div className="col-sm-6 col-md-1">
        {!loading ? (
          <>
            <span
              className="btn btn-success btn-block w-100"
              onClick={() => handleFilterSubmit()}
            >
              <i className="fa fa-search"></i>
            </span>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="col-sm-6 col-md-1">
        <span className="btn btn-dark btn-block w-100" onClick={toggleDropdown}>
          <i className="fa fa-cogs"></i>
        </span>
      </div>

      <div className={`${dropdownVisible ? "d-block" : "d-none"}`}>
        <span
          className="btn btn-warning"
          onClick={() => chamarModalMarcarFaltaConjunta()}
        >
          <i className="la la-money" /> {t("Pagamento")}
        </span>
        {"  "} | {"    "}
        <span className="btn btn-danger" onClick={() => chamarAccaoEliminar()}>
          <i className="fa fa-trash" /> {t("Delete")}
        </span>
        {"  "} | {"    "}
        {reporte == "pdf" ? (
          <>
            <span
              className="btn btn-danger"
              onClick={() => submitFolhasSalarialPDFdistintas()}
            >
              <i className="fa fa-file-pdf" /> {t("Folhas Distintas")}
            </span>
            {"  "} | {"    "}
            <span
              className="btn btn-danger"
              onClick={() => submitFolhasSalarialPDFunificadas()}
            >
              <i className="fa fa-file-pdf" /> {t("Folhas Unificadas")}
            </span>
            {"  "} | {"    "}
            <span
              className="btn btn-danger"
              onClick={() => submitFolhasSalarialPDFmesAno()}
            >
              <i className="fa fa-file-pdf" /> {t("Folhas Do Mes/Ano")}
            </span>
          </>
        ) : (
          <>
            <span
              className="btn btn-success"
              onClick={() => submitFolhasSalarialExcelunificadas()}
            >
              <i className="fa fa-file-excel" /> {t("Folha Para o Banco")}
            </span>
            {"  "} | {"    "}
            <span
              className="btn btn-success"
              onClick={() => submitFolhasSalarialExcelmesAno()}
            >
              <i className="fa fa-file-excel" /> {t("Folhas Simples Do Mes/Ano")}
            </span>
            {"  "} | {"    "}
            <span
              className="btn btn-success"
              onClick={() => submitFolhasSalarialDetalhadaExcelmesAno()}
            >
              <i className="fa fa-file-excel" /> {t("Folha Detalhada Do Mes/Ano")}
            </span>
          </>
        )}
        <div className="col-sm-6 col-md-2">
          <div className="input-block form-focus select-focus">
            <select
              className="form-control"
              onChange={(e) => setReporte(e.target.value)}
              value={reporte}
            >
              <option value="pdf">PDF</option>
              <option value="excel">EXCEL</option>
            </select>
            <label className="focus-label">{t("Tipo A Exportar")}</label>
          </div>
        </div>
        <hr></hr>
      </div>

      <span
        data-bs-toggle="modal"
        data-bs-target="#marcar_falta_conjunta_modal"
        id="triggerMarcarFaltaConjunta"
      ></span>

      <RegistarPagamentoFolhaSalarialConjuntaModal
        folhas={folhasSelecionadas}
        onSubmitSuccess={handleOnSubmitSuccess}
      />

      <ToastContainer />

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FolhaSalarialFilter;
