import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Avatar_01,
  Avatar_04,
  Avatar_05,
  Avatar_09,
  Avatar_10,
  Avatar_11,
  Avatar_12,
  Avatar_13,
  Avatar_16,
} from "../../../Routes/ImagePath";
import AttendenceModelPopup from "../../../components/modelpopup/AttendenceModelPopup";
import { useTranslation } from "react-i18next";

import AuthUser from "../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../components/Spinner";

import { startOfMonth, endOfMonth, format } from "date-fns";
import PresencaFilter from "../Administration/Users/PresencaFilter";

const TableAvatar = () => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } =
    AuthUser();

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [utilizadores, setUtilizador] = useState([]);
  const [cargos, setCargos] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const [ano, setAno] = useState(2024);
  const [mes, setMes] = useState(1);

  const handleSelect = (employee) => {
    setSelectedEmployees((prevSelected) => {
      if (prevSelected.includes(employee.id)) {
        return prevSelected.filter((id) => id !== employee.id);
      } else {
        return [...prevSelected, employee.id];
      }
    });
  };


  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  // Callback function to receive filtered users from UserFilter
  const handleFilterSubmit = (filteredUsers) => {
    setFilteredUsers(filteredUsers);
    setUtilizador(filteredUsers);
  };

  const getUtilizador = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append("nome", "");
      formData.append("telefone", "");
      formData.append("email", "");
      formData.append("estado", -1);
      formData.append("tipo", -1);
      formData.append("missao_id", getMissao()?.id);

      formData.append("cargo_id", -1);
      formData.append("departamento_id", -1);

      formData.append("mes", mes);
      formData.append("ano", ano);

      const response = await http.post("/utilizador/presencas", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("Dados");
      console.log(response.data.dados);

      setUtilizador(response.data.dados);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getCargos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/cargos_ativos/"+getMissao()?.id);

      setCargos(res.data.cargos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const getDepartamentos = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/departamentos_ativos/"+getMissao()?.id);

      setDepartamentos(res.data.departamentos);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  useEffect(() => {
    const currentDate = new Date();

    setAno(format(endOfMonth(currentDate), "yyyy"));

    setMes(format(endOfMonth(currentDate), "MM"));

    const fetchData = async () => {
      try {
        // Chama a função assíncrona getUtilizador()

        //await getUtilizador();

        await getCargos();
        await getDepartamentos();


      } catch (error) {
        console.error("Erro ao obter utilizadores:", error);
      }
    };

    // Chama a função fetchData() para iniciar o processo
    fetchData();
  }, []);

  const getTotalColaboradores = () => {
    return utilizadores?.length;
  };

  const getTotalHomens = () => {
    return utilizadores?.filter((utilizador) => utilizador?.genero == 1)
      ?.length;
  };

  const getTotalMulheres = () => {
    return utilizadores?.filter((utilizador) => utilizador?.genero == 2)
      ?.length;
  };

  const getTotalExpatriados = () => {
    return utilizadores?.filter((utilizador) => utilizador?.expatriado == 2)
      ?.length;
  };

  return (
    <>
      <PresencaFilter
        onFilterSubmit={handleFilterSubmit}
        tipo={3}
        departamentos={departamentos}
        cargos={cargos}
        utilizadoresSelecionados={selectedEmployees}
      />

      <div className="row">
        <div className="col-sm-12">
          <div className="table-responsive">
            <table className="table table-responsive table-striped mb-0">
              <thead>
                <tr>
                  <th>{t("Employee")}</th>
                  <th>T</th>
                  <th>J</th>
                  <th>F</th>
                  {[...Array(31).keys()].map((day) => (
                    <th key={day + 1}>{day + 1}</th>
                  ))}
                </tr>
              </thead>
              <tbody >
                {utilizadores?.map((employee) => (
                  <tr key={employee?.id}  className={` selectable ${
                    selectedEmployees.includes(employee.id)
                      ? "selected"
                      : ""
                  }  ${employee?.estado != 1 ? " desativado " : ""}`}
                  onClick={() => handleSelect(employee)}>
                    <td>
                      <h2 className="table-avatar">
                        <Link
                          className="avatar avatar-xs"
                          to={"/profile/" + employee?.id}
                        >
                          <img
                            alt=""
                            src={
                              url_root +
                              "/storage/" +
                              employee?.user?.fotografia
                            }
                          />
                        </Link>
                        <Link to={"/profile/" + employee?.user?.id}>
                          {employee?.user?.name}
                        </Link>
                      </h2>
                    </td>

                    <td>{employee?.total_faltas}</td> 
                    <td>{employee?.total_faltas_justificadas}</td> 
                    <td>{employee?.total_faltas_nao_justificadas}</td>        

                    {Object.keys(employee.absences).map((day) => (
                      <td key={day}>
                        {employee.absences[day] == 0 && (
                          <Link
                            to="#"
                            
                          >
                            <i className="fa fa-check text-success" />
                          </Link>
                        )}
                        {employee.absences[day] == 1 && (
                          <i className="fa fa-close text-danger" />
                        )}
                        {employee.absences[day] == 2 && (
                          <i className="fa fa-info text-warning" />
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <AttendenceModelPopup />
    </>
  );
};
export default TableAvatar;
