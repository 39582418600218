import React, { useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { toast, ToastContainer } from 'react-toastify';

import Swal from "sweetalert2";

import AuthUser from '../AuthUser';

const RolesPermissionsModal = ({ onSubmissionSuccess, acesso }) => {

  const { http, url_root, user, getToken, logout, verificarPermissao, empresa_selecionada, getMissao } = AuthUser();

  const [inputs, setInputs] = useState({});

  const logoutUser = ()=> {

    console.log("Clicou para sair");

    if(getToken() != undefined){
      logout();
      //return <Redirect to="/signIn" />;
      //props.history.push('/trainning/system/signIn');
      //history.push('');
      navigate("/");
    }

  }

  const navigate = useNavigate();

  const { t } = useTranslation();

  const [designacao, setDesignacao] = useState('');
  const [descricao, setDescricao] = useState('');
  const [idSelecionado, setIDselecionado] = useState('');

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const [imagem, setSelectedFile] = useState(null);

  const [permissaoAdicionar, setPermissaoAdicionar] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [permanecerNaPagina, setPermanecerNaPagina] = useState(true);

  const triggerCloseButtonClick = (buttonId) => {
    const closeButton = document.getElementById(buttonId);
    if (closeButton) {
      closeButton.dispatchEvent(new Event('click'));
    }
  };

  useEffect(() => {
    
    setPermissaoAdicionar(verificarPermissao('ACESSO', 'adicionar'));

    if(getToken() == undefined){
      logout();
      //return <Redirect to="/signIn" />;
      //props.history.push('/trainning/system/signIn');
      //history.push('');
      navigate("/");
    }

    setInputs(acesso);

  }, [permissaoAdicionar, acesso]);


  // INCIO REGISTO NOVO

  const handleFileChange = (e) => {
    // Lógica para processar a imagem, se necessário
    setSelectedFile(e.target.files[0]);
    // Restante do código
  };

  const toggleLoading = (value) => {
    setLoading(value);
  };

  const submitForm = async () => {

    if (verificarPermissao('ACESSO', 'adicionar')) {
      try {

        toggleLoading(true);

        // Basic form validation
        if (designacao.length < 2) {

          setError('O designação deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O designação deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }

        await uploadAcesso();


      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {
          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const uploadAcesso = async () => {

    if (verificarPermissao('ACESSO', 'adicionar')) {
      try {

        const formData = new FormData();

        formData.append('designacao', designacao);
        formData.append('descricao', descricao);
        formData.append('imagem', imagem);
        formData.append('estado', 0);
        formData.append('missao_id', getMissao()?.id);

        formData.append('empresa_id', empresa_selecionada.id);

        const response = await http.post('/acesso/register', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        // Show a success toast
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
            // Redirecionar para a lista de Acessos após o cadastro bem-sucedido

            setDesignacao('');
            setDescricao('');
            setSelectedFile(null);

            triggerCloseButtonClick('closeButton1');

            onSubmissionSuccess();

          },
        });

      } catch (error) {
        console.error(error);

        toast.error('Ocorreu um erro ao carregar o Acesso. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  //FIM REGISTO NOVO


  // INICIO EDITAR ACESSO

  const handleInputsValueChanged = (event) => {
    const name = event.target.name;
    const value = event.target.value;

    setInputs((prevInputs) => ({
      ...prevInputs,
      [name]: value,
    }));
  };


  const submitFormEditar = async () => {

    if (verificarPermissao('ACESSO', 'atualizar')) {
      try {
        toggleLoading(true);

        // Basic form validation
        if (inputs.designacao.length < 2) {
          setError('O designação do nível de acesso deve ter pelo menos dois caracteres.');
          setLoading(false);

          // Show a error toast
          toast.error('O designação do nivel de acesso deve ter pelo menos dois caracteres.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          return;
        }


        await updateAcessoEditar();


        // If the API call is successful, navigate to the dashboard
        //navigate('/dashboard');

      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError('Utilizador não autenticado.');

          // Show a error toast
          toast.error('Utilizador não autenticado.', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        } else {

          console.log("Erro:");
          console.log(error);

          setError('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado');

          // Show a error toast
          toast.error('Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado', {
            position: 'top-right',
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

        }
      } finally {
        toggleLoading(false);
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };

  const updateAcessoEditar = async () => {

    if (verificarPermissao('ACESSO', 'atualizar')) {

      try {
        const formData = new FormData();
        formData.append('id', acesso.id);
        formData.append('designacao', inputs?.designacao);
        formData.append('descricao', inputs?.descricao);
        formData.append('imagem', imagem);

        const response = await http.post('/acesso/update', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        // Show a success toast
        toast.success(response.data.message, {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          onClose: () => {
           

            triggerCloseButtonClick('closeButton2');

            onSubmissionSuccess();


          }
        });

      } catch (error) {
        console.log("Erro:");
        console.log(error);

        toast.error('Ocorreu um erro ao carregar o Acesso. Por favor, tente novamente mais tarde.', {
          position: 'top-right',
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      }

    } else {
      Swal.fire({
        title: "SEM PERMISSÃO",
        text: "Lamento, mas não tem permissão para realizar esta operação, contacte o administrador do sistema",
        type: "error",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Certo, entendi!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      });
    }
  };


  // FIM EDITAR ACESSO

  return (
    <>
      {/* Add Role Modal */}
      <div id="add_role" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t('Add Role')}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeButton1"

              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    {t('Role Name')} <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    value={designacao}
                    onChange={e => setDesignacao(e.target.value)}
                  />
                </div>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    {t('Description')}<span className="text-danger">*</span>
                  </label>
                  <textarea
                    className="form-control"
                    onChange={e => setDescricao(e.target.value)}
                  >{descricao}
                  </textarea>
                </div>
                <div className="submit-section">
                  <a
                    className="btn btn-primary submit-btn"
                    onClick={submitForm}
                  >
                    {loading ? 'Carregando' : t('Submit')}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Add Role Modal */}


    
      {/* Edit Role Modal */}
      <div id="edit_role" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content modal-md">
            <div className="modal-header">
              <h5 className="modal-title">{t('Edit Role')}</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                id="closeButton2"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    {t('Role Name')} <span className="text-danger">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    name="designacao"
                    value={inputs?.designacao} onChange={handleInputsValueChanged}
                  />
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    {t('Description')} <span className="text-danger">*</span>
                  </label>
                  <textarea  name="descricao"
                    className="form-control"
                    value={inputs?.descricao} onChange={handleInputsValueChanged}
                  >{inputs?.descricao}
                  </textarea>
                </div>

                <div className="submit-section">
                  <a
                    className="btn btn-primary submit-btn"
                    onClick={submitFormEditar}>
                    {loading ? 'Carregando' : t('Save')}
                  </a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* /Edit Role Modal */}

      <ToastContainer />

    </>
  );
};

export default RolesPermissionsModal;
